import Model from './BaseEntity';

export default class EventDetail extends Model {

    static entity = 'eventdetail';

    static fields() {
        return {
            id: this.attr(''),
            parent_id: this.attr(null).nullable(),
            contact: this.attr({
                email: null,
                name: null,
                phone: null,
                image: null,
            }),
            description: this.string(null).nullable(),
            images: this.attr([]),
            logos: this.attr([]),
            registration: this.attr({
                note: null,
                price: null,
                type: null
            })
        }
    }

    get serialized() {
        return {
            id: this.id,
            contact: this.contact,
            description: this.description,
            images: this.images,
            logos: this.logos,
            registration: this.registration,
        }
    }

}
