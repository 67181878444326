<template>
    <section class="section">
        <div class="block is-flex is-justify-content-flex-end">
            <b-button :to="{ name: 'PageNotificationForm' }" tag="router-link" class="is-success">
                <b-icon icon="plus-circle" size="is-small" class="mr-1"></b-icon>Vytvořit upozornění
            </b-button>
        </div>
        <b-table
            :data="this.notifications"
            :per-page="perPage"
            :paginated="true"
            :current-page.sync="currentPage"
            :pagination-simple="true"
            :pagination-position="'bottom'"
            default-sort="timestamp"
            :default-sort-direction="'desc'"
            focusable
        >
            <b-table-column field="timestampDate" label="Datum" v-slot="props" sortable>
                {{ props.row.timestampDate | dateFormat('DD. MM. YYYY HH:mm') }}
            </b-table-column>
            <b-table-column field="building" label="Budova" v-slot="props" sortable>
                <template v-if="props.row.target[0] === 'building'">
                    {{ keyToBuilding(props.row.target[1]) }}
                </template>
            </b-table-column>
            <b-table-column field="title" label="Title" v-slot="props" sortable>
                {{ props.row.title }}
            </b-table-column>
            <b-table-column field="title" label="Zpráva" v-slot="props">
                <div v-html="props.row.message"></div>
            </b-table-column>
        </b-table>
    </section>
</template>

<script>
    import Notification from '@/store/models/Notification';
    import Business from "@/store/models/Business";

    export default {
        name: 'PageNotifications',
        data() {
            return {
                currentPage: 1,
                perPage: 15,
                buildings: []
            }
        },
        computed: {
            notifications() {
                return this.$store.getters['entities/notification/query']().withAll().orderBy('orderby', 'desc').get();
            }
        },
        methods: {
            edit(id) {
                this.$router.push({name: 'PageNotificationForm', params: { id } });
            },
            async deleteDoc(id) {
                let lc = this.$buefy.loading.open();
                await Notification.delete(id);
                await Notification.fetch();
                lc.close();
            },
            buildingsCategory() {
                let buildings = this.$store.getters['entities/business/query']().where('category', 'office').withAll().get();
                for(let i = 0; i < buildings.length; i++) {
                    let row = {
                        id: buildings[i].id,
                        name: buildings[i].name.cs_CZ
                    };
                    this.buildings.push(row);
                }
            },
            keyToBuilding(key) {
                for(let i = 0; i < this.buildings.length; i++) {
                    if (key === this.buildings[i].id) {
                        return this.buildings[i].name;
                    }
                }
                return '';
            }
        },
        async created() {
            let lc = this.$buefy.loading.open();
            await Notification.fetch();
            await Business.fetch();
            this.buildingsCategory();
            lc.close();
        }
    }
</script>
