import * as api from '../../../firebase-service.js';

export default {
    namespaced: true,
    actions: {
        fetch(context, test) {
            console.log('modules', test);
            return null;
            // return api.getAllTranslates('parking', parentId).then(parking => {
            //     return context.dispatch('create', { data: parking })
            // })
        },
        update(context, { parentId: parentId, id: id, data: data }) {
            return api.updateTranslate('parking', parentId, id, data).then(() => {
                return fetch(context);
            })
        },
        createNew(context, { parentId: parentId, data: data }) {
            return api.createTranslate('parking', parentId, data).then((item) => {
               return item
            });
        }
    }
}
