import Model from './BaseEntity';

export default class ParkingTranslate extends Model {

    static entity = 'parkingtranslate';

    static fields() {
        return {
            id: this.attr(''),
            parent_id: this.attr(null).nullable(),
            cover: this.string(null).nullable(),
            description: this.string(null).nullable(),
            lot_prices: this.attr([]),
            lot_title: this.attr(null).nullable(),
            note: this.string(null).nullable()
        }
    }

    get serialized() {
        return {
            id: this.id,
            cover: this.cover,
            description: this.description,
            lot_prices: this.lot_prices,
            lot_title: this.lot_title,
            note: this.note
        }
    }

}
