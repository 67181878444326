<template>
    <section class="section">
        <div class="block is-flex is-justify-content-flex-end">
            <b-button :to="{ name: 'PageNewsForm' }" tag="router-link" class="is-success">
                <b-icon icon="plus-circle" size="is-small" class="mr-1"></b-icon>Vytvořit aktualitu
            </b-button>
        </div>
        <b-table
            :data="this.news"
            :per-page="perPage"
            :paginated="true"
            :current-page.sync="currentPage"
            :pagination-simple="true"
            :pagination-position="'bottom'"
            default-sort="listDate"
            default-sort-direction="desc"
            :rowClass="rowClass"
        >
            <b-table-column field="listDate" label="Datum" v-slot="props" sortable>
                {{ props.row.listDate | dateFormat('DD. MM. YYYY HH:mm') }}
            </b-table-column>
            <b-table-column field="name.cs_CZ" label="Název" v-slot="props" :searchable="true">
                {{ props.row.name.cs_CZ }}
            </b-table-column>
            <b-table-column field="state" label="Publikováno" v-slot="props">
                <span :class="
                          [
                              'tag',
                              {'is-danger': props.row.state !== 'public'},
                              {'is-success': props.row.state === 'public'}
                          ]">
                      {{ props.row.state === 'public' ? 'Yes' : 'No' }}
                </span>
            </b-table-column>
            <b-table-column field="action" label="" v-slot="props">
                <div class="buttons">
                    <b-button v-on:click="edit(props.row.id)">
                        <b-icon icon="circle-edit-outline" size="is-small" class="mr-1"></b-icon>Upravit
                    </b-button>
                    <b-button v-on:click="deleteDoc(props.row.id)" type="is-danger">
                        <b-icon icon="circle-edit-outline" size="is-small" class="mr-1"></b-icon>Smazat
                    </b-button>
                    <b-button v-on:click="toggleHide(props.row)" :type="props.row.state !== 'hidden' ? 'is-light' : 'is-success is-light'">
                        <b-icon icon="eye" size="is-small" class="mr-1"></b-icon>{{ props.row.state !== 'hidden' ? 'Skrýt' : 'Publikovat' }}
                    </b-button>
                </div>
            </b-table-column>
        </b-table>
    </section>
</template>

<script>
    import News from '@/store/models/News';

    export default {
        name: 'PageNews',
        data() {
            return {
                currentPage: 1,
                perPage: 15
            }
        },
        computed: {
            news() {
                return this.$store.getters['entities/news/query']().withAll().orderBy('orderby', 'desc').get();
            }
        },
        methods: {
            edit(id) {
                this.$router.push({name: 'PageNewsForm', params: { id } });
            },
            async deleteDoc(id) {
                let lc = this.$buefy.loading.open();
                await News.delete(id);
                await News.fetch();
                lc.close();
            },
            toggleHide(item) {
                if (item.state === 'hidden') {
                    this.update(item.id, 'public');
                } else {
                    this.update(item.id, 'hidden');
                }
            },
            async update(id, value) {
                let lc = this.$buefy.loading.open();
                await News.update({ id: id, data: {state: value} });
                await News.fetch();
                lc.close();
            },
            rowClass(item) {
                if(item.state === 'hidden'){
                    return 'is-checked-as-hidden';
                }
            }
        },
        async created() {
            let lc = this.$buefy.loading.open();
            await News.fetch();
            lc.close();
        }
    }
</script>
