import * as api from '../../../firebase-service.js';

export default {
    namespaced: true,
    actions: {
        fetch(context, record) {
            var savedItems,
                promises = [];

            if (record.filter.id) {
                return api
                    .getOne("enum", record.filter.id)
                    .then(_results => {
                        savedItems = _results;
                        for (var r in savedItems) {
                            promises.push(api.getAllTranslations("enum", savedItems[r].id));
                        }
                        return Promise.all(promises);
                    })
                    .then(_translations => {
                        for (var i in _translations) {
                            savedItems[i].detail = _translations[i][0];
                        }
                        return context.dispatch("create", {data: savedItems});
                    });
            } else {
                return api.getAll('enum').then(items => {
                    return context.dispatch('create', {data: items})
                });
            }
        },
    },
}
