<template>
    <section class="section">
        <div class="container">
            <b-field label="E-mail">
                <b-input v-model="email"></b-input>
            </b-field>
            <b-field label="Heslo">
                <b-input type="password" v-model="password"></b-input>
            </b-field>
            <div>
                <b-button type="is-primary" v-on:click="login()">
                    Přihlásit se
                </b-button>
            </div>
        </div>
    </section>
</template>

<script>

    import firebase from 'firebase';
    import LoggedUser from '@/store/models/LoggedUser';

    export default {
        name: 'PageLogin',
        data() {
            return {
                email: '',
                password: ''
            }
        },
        methods: {
            login() {
                firebase.auth().signInWithEmailAndPassword(this.email, this.password).then(data => {
                    //check access
                    firebase
                        .firestore()
                        .collection('access')
                        .doc(data.user.uid)
                        .get()
                        .then( access => {
                            if (access.data()) {
                                let accessData = access.data();
                                if (accessData.admin) {
                                    LoggedUser.create({
                                        data: {
                                            id: data.user.uid,
                                            email: data.user.email
                                        }
                                    });
                                    this.$router.push({ name: 'PageNewsList' })
                                } else {
                                    this.$buefy.dialog.alert('Přístup zamítnut.');
                                }
                            } else {
                                this.$buefy.dialog.alert('Přístup zamítnut.');
                            }
                        }).catch(() => {
                        this.$buefy.dialog.alert('Přístup zamítnut.');
                    });

                }).catch(e => {
                    if (e.code === 'auth/invalid-email') {
                        this.$buefy.dialog.alert('Špatný e-mail.');
                    } else if (e.code === 'auth/user-not-found') {
                        this.$buefy.dialog.alert('Uživatel nenalezen.');
                    } else if (e.code === 'auth/wrong-password') {
                        this.$buefy.dialog.alert('Španté heslo.');
                    } else {
                        this.$buefy.dialog.alert('Nelze se přihlásit.');
                    }
                });
            }
        }
    }
</script>
