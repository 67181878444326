import * as api from '../../../firebase-service.js';

export default {
    namespaced: true,
    actions: {
        fetch(context) {
            return api.getAll('real_estate').then(items => {
                return context.dispatch('create', {data: items})
            });
        },
        update(context, { id: id, data: data }) {
            return api.update('real_estate', id, data).then(() => {
                return fetch(context);
            })
        },
        createNew(context, { id: id, data: data }) {
            return api.create('real_estate', id, data).then(() => {
                    return id
            });
        },
        generateId() {
            return api.generateId('real_estate');
        },
        delete(context, id) {
            return api.deleteDocument('real_estate', id);
        }
    }
}
