import Model from './BaseEntity';
import BusinessTranslate from "@/store/models/BusinessTranslate";

export default class Business extends Model {

    static entity = 'business';

    static fields() {
        return {
            id: this.attr(''),
            category: this.string(null).nullable(),
            sub_category: this.string(null).nullable(),
            daily_menu: this.string(null).nullable(),
            contact: this.attr({
                address: null,
                email: null,
                name: null,
                phone: null,
                web: null
            }),
            cover: this.string(null).nullable(),
            location: this.attr({
                lat: null,
                lng: null,
                name: null
            }),
            logo: this.string(null).nullable(),
            name: this.attr({
                cs_CZ: null,
                en_US: null
            }),
            state: this.string('public'),
            order: this.attr(100),
            translations: this.hasMany(BusinessTranslate, 'parent_id')
        }
    }

    get serialized() {
        return {
            category: this.category,
            sub_category: this.sub_category,
            daily_menu: this.daily_menu,
            contact: this.contact,
            cover: this.cover,
            location: this.location,
            logo: this.logo,
            name: this.name,
            state: this.state,
            order: this.order
        }
    }

}
