<template>
    <section class="section">
        <h1 class="title is-1">Dashboard</h1>
    </section>
</template>

<script>
    export default {
        name: 'PageDashboard'
    }
</script>
