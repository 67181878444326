<template>
    <section class="section">
        <div class="block is-flex is-justify-content-flex-end">
            <b-button :to="{ name: 'PageEventForm' }" tag="router-link" class="is-success">
                <b-icon icon="plus-circle" size="is-small" class="mr-1"></b-icon>Vytvořit akci
            </b-button>
        </div>
        <b-table
            :data="this.event"
            :per-page="perPage"
            :paginated="true"
            :current-page.sync="currentPage"
            :pagination-simple="true"
            :pagination-position="'bottom'"
            :default-sort="'listDate'"
            :default-sort-direction="'desc'"
        >
            <b-table-column field="listDate" label="Začátek akce" v-slot="props" sortable>
              {{ props.row.listDate | dateFormat('DD. MM. YYYY HH:mm') }}
            </b-table-column>
            <b-table-column field="listPublish" label="Zobrazit od" v-slot="props" sortable>
                {{ props.row.listPublish | dateFormat('DD. MM. YYYY HH:mm') }}
            </b-table-column>
            <b-table-column field="name" label="Název" v-slot="props"  :searchable="true">
                {{ props.row.name }}
            </b-table-column>
            <b-table-column field="state" label="Stav" v-slot="props">
                <span :class="
                          [
                              'tag',
                              {'is-danger': props.row.state === 'denied'},
                              {'is-warning': props.row.state === 'new'},
                              {'is-success': props.row.state === 'public'}
                          ]">
                      {{ $t('event.state.' + props.row.state) }}
                </span>
            </b-table-column>
            <b-table-column field="action" label="" v-slot="props">
                <div class="buttons">
                    <b-button v-on:click="edit(props.row.id)">
                        <b-icon icon="circle-edit-outline" size="is-small" class="mr-1"></b-icon>Upravit
                    </b-button>
                    <b-button v-on:click="deleteDoc(props.row.id)" type="is-danger">
                        <b-icon icon="circle-edit-outline" size="is-small" class="mr-1"></b-icon>Smazat
                    </b-button>
                </div>
            </b-table-column>
        </b-table>
    </section>
</template>

<script>
    import Event from '@/store/models/Event';

    export default {
        name: 'PageEvents',
        data() {
            return {
                currentPage: 1,
                perPage: 15
            }
        },
        computed: {
            event() {
                return this.$store.getters['entities/event/query']().withAll().orderBy('orderby', 'desc').get();
            }
        },
        methods: {
            edit(id) {
                this.$router.push({name: 'PageEventForm', params: { id } });
            },
            // async update(id, value) {
            //     let lc = this.$buefy.loading.open();
            //     await Event.update({ id: id, data: {public: value} });
            //     lc.close();
            // }
            async deleteDoc(id) {
                let lc = this.$buefy.loading.open();
                await Event.delete(id);
                await Event.fetch();
                lc.close();
            }
        },
        async created() {
            let lc = this.$buefy.loading.open();
            await Event.fetch();
            lc.close();
        }
    }
</script>
