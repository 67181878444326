import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core';
import database from "@/store/database";

// import timestampPlugin from "@/store/ormPlugins/timestampType";
// VuexORM.use(timestampPlugin);

Vue.use(Vuex);

const store = new Vuex.Store({
  plugins: [VuexORM.install(database)]
});

export default store;
