import Model from './BaseEntity';
import ParkingTranslate from "@/store/models/ParkingTranslate";

export default class Parking extends Model {

    static entity = 'parking';

    static fields() {
        return {
            id: this.attr(''),
            name: this.attr(''),
            translations: this.hasMany(ParkingTranslate, 'parent_id')
        }
    }

    get serialized() {
        return {
            name: this.name
        }
    }

}
