<template>
    <div id="app">
        <ComponentNavigation></ComponentNavigation>
        <router-view/>
    </div>
</template>

<script>
    import ComponentNavigation from '@/views/Component/ComponentNavigation';
    export default {
        components: {
            ComponentNavigation
        }
    }
</script>

<style>
    tr.is-checked-as-hidden{
        background: #aaaaaa;
        color: #fff;
    }
</style>
