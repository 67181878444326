import Model from './BaseEntity';

export default class Event extends Model {

    static entity = 'enum';

    static fields() {
        return {
            id: this.attr(''),
            buildings: this.attr([]),
            category: this.attr([]),
            parking_category: this.attr([]),
            registration: this.attr([]),
            state: this.attr([]),
        }
    }

}
