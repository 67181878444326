import * as api from '../../../firebase-service.js';

export default {
    namespaced: true,
    actions: {
        fetch(context, record) {
            var savedItems,
                promises = [];

            if (record.filter.id) {
                return api
                    .getOne("parking", record.filter.id)
                    .then(_results => {
                        savedItems = _results;
                        for (var r in savedItems) {
                            promises.push(api.getAllTranslations("parking", savedItems[r].id));
                        }
                        return Promise.all(promises);
                    })
                    .then(_translations => {
                        for (var i in _translations) {
                            savedItems[i].translations = _translations[i];
                        }
                        return context.dispatch("create", {data: savedItems});
                    });
            } else {
                return api.getAll('parking').then(items => {
                    return context.dispatch('create', {data: items})
                });
            }
        },
        update(context, {id: id, data: data, translations: translations}) {
            var promises = [];
            return api.update('parking', id, data)
                .then(() => {
                    for (var r in translations) {
                        let translateId = translations[r].id;
                        delete translations[r].id;
                        promises.push(api.updateTranslate("parking", id, translateId, translations[r]));
                    }
                    return Promise.all(promises);
                })
                .then(() => {
                    return fetch(context);
                });
        },
        createNew(context, {id: id, data: data, translations: translations}) {
            var promises = [];
            return api.create('parking', id, data)
                .then(() => {
                    for (var r in translations) {
                        let translateId = translations[r].id;
                        delete translations[r].id;
                        promises.push(api.createTranslate("parking", id, translateId, translations[r]));
                    }
                    return Promise.all(promises);
                })
                .then(() => {
                    return id;
                });
        },
        generateId() {
            return api.generateId('parking');
        },
        delete(context, id) {
            api.deleteCollectionDocument('parking', id, 'data', 'cs_CZ');
            api.deleteCollectionDocument('parking', id, 'data', 'en_US');
            return api.deleteDocument('parking', id);
        }
    }
}
