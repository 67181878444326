<template>
    <ValidationProvider
        :vid="vid"
        :name="$attrs.name || $attrs.label"
        :rules="rules"
        v-slot="{ errors, valid }"
    >
        <b-field
            v-bind="$attrs"
            :type="{ 'is-danger': errors[0], 'is-success': valid }"
            :message="errors"
            :label="$attrs.name || $attrs.label"
            :label-position="labelPosition"
            class="my-5"
        >
            <template v-if="$attrs.type === 'textarea' && !$attrs.noeditor">
              <editor v-model="innerValue" v-bind="$attrs"/>
            </template>
            <template v-else>
              <b-input v-model="innerValue" v-bind="$attrs"></b-input>
            </template>
        </b-field>
    </ValidationProvider>
</template>

<script>
// from https://buefy.org/extensions/veevalidate
import Editor from "@/views/Component/Editor";
import { ValidationProvider, extend } from "vee-validate";
import { required } from 'vee-validate/dist/rules';

extend('required', {
  ...required,
  message: 'This field is required'
});

// import * as rules from 'vee-validate/dist/rules';
//
// Object.keys(rules).forEach(rule => {
//   extend(rule, rules[rule]);
// });
//
// // with typescript
// for (let [rule, validation] of Object.entries(rules)) {
//   extend(rule, {
//     ...validation
//   });
// }

export default {
    components: {
        ValidationProvider,
        Editor
    },
    props: {
        vid: {
            type: String
        },
        rules: {
            type: [Object, String],
            default: ""
        },
        // must be included in props
        value: {
            type: null
        }
    },
    data: () => ({
        innerValue: "",
        labelPosition: 'on-border',
    }),
    watch: {
        // Handles internal model changes.
        innerValue(newVal) {
            this.$emit("input", newVal);
        },
        // Handles external model changes.
        value(newVal) {
            this.innerValue = newVal;
        }
    },
    created() {
        if (this.value) {
            this.innerValue = this.value;
        }
    }
};
</script>
