<template>
    <section class="section">
        <b-table
            :data="this.users"
            :per-page="perPage"
            :paginated="true"
            :current-page.sync="currentPage"
            :pagination-simple="true"
            :pagination-position="'bottom'"
            default-sort="timestamp"
            :default-sort-direction="'desc'"
            focusable
        >
            <b-table-column field="userinfo.image" label="Obrázek" v-slot="props" sortable>
                <template v-if="props.row.userinfo && props.row.userinfo.image.length > 0">
                    <a :href="props.row.userinfo.image" target="_blank"><img :src="props.row.userinfo.image" width="100"></a>
                </template>
            </b-table-column>
            <b-table-column field="userinfo.name" label="Jméno" v-slot="props" sortable :searchable="true">
                <template v-if="props.row.userinfo">
                {{ props.row.userinfo.name }}
                </template>
            </b-table-column>
            <b-table-column field="userinfo.email" label="Email" v-slot="props" sortable :searchable="true">
                <template v-if="props.row.userinfo">
                {{ props.row.userinfo.email }}
                </template>
            </b-table-column>
            <b-table-column field="userinfo.phone" label="Telefon" v-slot="props">
                <template v-if="props.row.userinfo">
                    {{ props.row.userinfo.phone }}
                </template>
            </b-table-column>
        </b-table>
    </section>
</template>

<script>
    import User from '@/store/models/User';
    import UserInfo from '@/store/models/UserInfo';

    export default {
        name: 'PageUsers',
        data() {
            return {
                currentPage: 1,
                perPage: 20
            }
        },
        computed: {
            users() {
                //return this.$store.getters['entities/user/query']().withAll().orderBy('userinfo.name', 'asc').get();
                //return this.$store.getters['entities/user/query']().with('userinfo', (query) => {query.orderBy('name')}).get();
                //return User.query().with('userinfo').orderBy('userinfo.name', 'asc').get();
                return User.query().withAll().whereHas('userinfo').orderBy('user.userinfo.name').get();
            }
        },
        methods: {
            addUserInfo() {

            }
        },
        async created() {
            let lc = this.$buefy.loading.open();
            await UserInfo.fetch();
            await User.fetch();
            lc.close();
        }
    }
</script>
