import Model from './BaseEntity';
import NewsTranslate from "@/store/models/NewsTranslate";
import {myFirebase} from "../../../firebase-service";

export default class News extends Model {

    static entity = 'news';

    static fields() {
        return {
            id: this.attr(''),
            date: this.attr(myFirebase.firestore.Timestamp.fromDate(new Date())),
            cover: this.string(''),
            name: this.attr({
                    cs_CZ: '',
                    en_US: ''
                }),
            timestamp: this.attr(null),
            state: this.string('hidden'),
            translations: this.hasMany(NewsTranslate, 'parent_id')
        }
    }

    get serialized() {
        return {
            date: this.convertTime(this.date),
            cover: this.cover,
            name: this.name,
            timestamp: myFirebase.firestore.Timestamp.fromDate(new Date()),
            state: this.state
        }
    }

    convertTime(value) {
        if (value instanceof Date) {
            return myFirebase.firestore.Timestamp.fromDate(value);
        } else if (value instanceof myFirebase.firestore.Timestamp) {
            return value;
        } else {
            return myFirebase.firestore.Timestamp.fromDate(new Date(value.seconds * 1000));
        }
    }

    get dateToDate () {
        if (this.date) {
            let date = new Date(this.date.seconds * 1000);
            // this.date = myFirebase.firestore.Timestamp.fromDate(date);
            return date;
        } else {
            let date = new Date();
            // this.date = myFirebase.firestore.Timestamp.fromDate(date);
            return date;
        }
    }

    set dateToDate (value) {
        if (value instanceof Date) {
            this.date = myFirebase.firestore.Timestamp.fromDate(value);
        } else {
            return null;
        }
    }

    get listDate () {
        if (this.date) {
            return new Date(this.date.seconds * 1000);
        } else {
            return new Date();
        }
    }

    get orderby () {
        if (this.date) {
            return this.date.seconds;
        } else {
            return new Date().getTime() / 1000;
        }
    }

}
