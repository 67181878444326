import * as api from '../../../firebase-service.js';

export default {
    namespaced: true,
    actions: {
        fetch(context) {
            return api.getAll('user').then(users => {
                return context.dispatch('create', { data: users })
            })
        }
    }
}
