<template>
    <section class="section">
        <div class="block is-flex is-justify-content-flex-end">
            <b-button :to="{ name: 'PageRealEstateForm' }" tag="router-link" class="is-success">
                <b-icon icon="plus-circle" size="is-small" class="mr-1"></b-icon>Vytvořit pronájem
            </b-button>
        </div>
        <b-table
            :data="this.realestates"
            :per-page="perPage"
            :paginated="true"
            :current-page.sync="currentPage"
            :pagination-simple="true"
            :pagination-position="'bottom'"
            default-sort="listDate"
            default-sort-direction="desc"
            :rowClass="rowClass"
        >
            <b-table-column field="category" label="Kategorie" :searchable="true">
                <template slot="searchable" slot-scope="props">
                    <b-select v-if="enums" placeholder="Vyber kategorii" v-model="props.filters[props.column.field]">
                        <option
                            :value="null"
                            :key="'0a'">
                            Zrušit výběr
                        </option>
                        <option
                            v-for="option in enums.category"
                            :value="option"
                            :key="option">
                            {{ $t('realestate.category.' + option) }}
                        </option>
                    </b-select>
                </template>
                <template v-slot="props">{{ $t('realestate.category.' + props.row.category) }}</template>
            </b-table-column>
            <b-table-column field="title.cs_CZ" label="Název" v-slot="props" :searchable="true">
                {{ props.row.title.cs_CZ }}
            </b-table-column>
            <b-table-column field="action" label="" v-slot="props">
                <div class="buttons">
                    <b-button v-on:click="edit(props.row.id)">
                        <b-icon icon="circle-edit-outline" size="is-small" class="mr-1"></b-icon>Upravit
                    </b-button>
                    <b-button v-on:click="deleteDoc(props.row.id)" type="is-danger">
                        <b-icon icon="circle-edit-outline" size="is-small" class="mr-1"></b-icon>Smazat
                    </b-button>
                    <b-button v-on:click="toggleHide(props.row)" :type="props.row.state !== 'hidden' ? 'is-light' : 'is-success is-light'">
                        <b-icon icon="eye" size="is-small" class="mr-1"></b-icon>{{ props.row.state !== 'hidden' ? 'Skrýt' : 'Publikovat' }}
                    </b-button>
                </div>
            </b-table-column>
        </b-table>
    </section>
</template>

<script>
    import RealEstate from '@/store/models/RealEstate';
    import Enum from "@/store/models/Enum";

    export default {
        name: 'PageRealEstates',
        data() {
            return {
                currentPage: 1,
                perPage: 15
            }
        },
        computed: {
            realestates() {
                return this.$store.getters['entities/realestate/query']().withAll().get();
            },
            enums() {
                return this.$store.getters["entities/enum/query"]()
                    .find('real_estate') || null;
            },
        },
        methods: {
            edit(id) {
                this.$router.push({name: 'PageRealEstateForm', params: { id } });
            },
            async deleteDoc(id) {
                let lc = this.$buefy.loading.open();
                await RealEstate.delete(id);
                await RealEstate.fetch();
                lc.close();
            },
            toggleHide(item) {
                if (item.state === 'hidden') {
                    this.update(item.id, 'public');
                } else {
                    this.update(item.id, 'hidden');
                }
            },
            async update(id, value) {
                let lc = this.$buefy.loading.open();
                await RealEstate.update({ id: id, data: {state: value} });
                await RealEstate.fetch();
                lc.close();
            },
            rowClass(item) {
                if(item.state === 'hidden'){
                    return 'is-checked-as-hidden';
                }
            }
        },
        async created() {
            let lc = this.$buefy.loading.open();
            await RealEstate.fetch();
            await Enum.fetch();
            lc.close();
        },
    }
</script>
