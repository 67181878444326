<script src="../../store/modules/notification.js"></script>
<template>
    <section class="section">
        <div class="container">
            <h1 class="title is-1">Upozornění</h1>
            <template v-if="this.loaded">
                <validation-observer ref="observer" tag="form" @submit.prevent="submit()">
                    <div class="block">
                        <b-field label="Budova" :label-position="labelPosition">
                            <div class="box">
                                <b-select placeholder="Vyber budovu" v-model="notificationItem.target[1]">
<!--                                    <optgroup v-for="category in buildingsCategory" :label="$t('building.category.' + category)" :key="category">-->
                                        <option
                                            v-for="option in buildingsCategory"
                                            :value="option.id"
                                            :key="option.id">
                                            {{ option.name }}
                                        </option>
<!--                                    </optgroup>-->
                                </b-select>
                            </div>
                        </b-field>
                    </div>
                    <div class="block">
                        <b-field label="Title" :label-position="labelPosition">
                            <div class="box">
                                <b-input-with-validation type="text" rules="required" v-model="notificationItem.title" />
                            </div>
                        </b-field>
                        <b-field label="Zpráva" :label-position="labelPosition">
                            <div class="box">
                                <b-input-with-validation type="textarea" noeditor="1" rules="required" v-model="notificationItem.message" />
                            </div>
                        </b-field>
                        <b-field label="Detail zprávy" :label-position="labelPosition">
                            <div class="box">
                                <b-input-with-validation type="textarea" v-model="notificationItem.description" />
                            </div>
                        </b-field>
                    </div>
                    <button class="button is-success">Odeslat</button>
                </validation-observer>
            </template>
        </div>
    </section>
</template>

<script>

import { ValidationObserver } from 'vee-validate';
import BInputWithValidation from '@/form/BInputWithValidation';
import Notification from "@/store/models/Notification";
import UploadComponent2 from "@/components/UploadComponent2";
import Editor from "@/views/Component/Editor";
import Enum from "@/store/models/Enum";
// import Building from "@/store/models/Building";
import Business from "@/store/models/Business";

export default {
    name: 'PageNotificationForm',
    components: {
        ValidationObserver,
        BInputWithValidation,
        UploadComponent2,
        Editor
    },
    data() {
        return {
            refresh: 0,
            loaded: false,
            addLoading: false,
            activeTab: 'cs_CZ',
            labelPosition: 'on-border',
            generatedId: '',
            images: [],
            poi: [],
            notificationItem: null
        }
    },
    computed: {
        // notificationItem() {
        //     if (this.$route.params.id) {
        //         return this.$store.getters["entities/notification/query"]()
        //             .find(this.$route.params.id) || null;
        //     } else {
        //         let n = new Notification();
        //         //clear all variable manualy? :(
        //
        //         return n;
        //     }
        // },
        enums() {
            return this.$store.getters["entities/enum/query"]()
                .find('notification') || null;
        },
        buildings() {
            return this.$store.getters['entities/business/query']().where('category', 'office').withAll().orderBy('name').get();
        },
        buildingsCategory() {
            let buildings = this.$store.getters['entities/business/query']().where('category', 'office').withAll().get();
            let data = [];
            let lastCategory = '';
            for(let i = 0; i < buildings.length; i++) {
                let row = {
                    id: buildings[i].id,
                    name: buildings[i].name.cs_CZ
                };
                data.push(row);
            }
            data.sort(this.compareBuildings);
            return data;
        },
        // buildingsCategory() {
        //     let categories = this.$store.getters['entities/building/query']().withAll().orderBy('category').get();
        //     let data = [];
        //     let lastCategory = '';
        //     for(let i = 0; i < categories.length; i++) {
        //         if (categories[i].category !== lastCategory) {
        //             lastCategory = categories[i].category;
        //             data.push(categories[i].category);
        //         }
        //     }
        //     return data;
        // },
        actualId() {
            if (this.$route.params.id) {
                return this.$route.params.id;
            } else {
                return this.generatedId;
            }
        }
    },
    methods: {
        // TODO: can be adding and removing be better? without loading property?
        async submit() {
            const isValid = await this.$refs.observer.validate();
            if (!isValid) {
                alert(this.$t('form.error'));
            } else {
                let lc = this.$buefy.loading.open();

                // create
                let createdId = await Notification.dispatch('createNew', {
                    id: this.generatedId,
                    data: this.notificationItem.serialized
                });
                await Notification.fetch({id: createdId});
                this.$router.push({name: 'PageNotificationList', params: {id: createdId}});

                lc.close();
            }
        },
        compareBuildings( a, b ) {
            if ( a.name < b.name ){
                return -1;
            }
            if ( a.name > b.name ){
                return 1;
            }
            return 0;
        }
        // buildingInCategory(category) {
        //     let data = [];
        //     for(let i = 0; i < this.buildings.length; i++) {
        //         if (category === this.buildings[i].category) {
        //             data.push(this.buildings[i]);
        //         }
        //     }
        //     return data;
        // },
        // fill() {
        //     const data = [
        //         { name:'A', category: 'admin' },
        //         { name:'B', category: 'admin' },
        //         { name:'C', category: 'admin' },
        //         { name:'D', category: 'admin' },
        //         { name:'E', category: 'admin' },
        //         { name:'Filadelfie', category: 'admin' },
        //         { name:'G', category: 'admin' },
        //         { name:'Alpha', category: 'admin' },
        //         { name:'Beta', category: 'admin' },
        //         { name:'Delta', category: 'admin' },
        //         { name:'Gamma', category: 'admin' },
        //         { name:'Villas I', category: 'admin' },
        //         { name:'Villas II', category: 'admin' },
        //
        //         { name:'BRUMLOVKA', category: 'service' },
        //
        //         { name:'Rezidenční park Baarova', category: 'residence' },
        //         { name:'Residence VILLAS', category: 'residence' },
        //         { name:'Oliva', category: 'residence' },
        //
        //         { name:'Křesťanská střední škola', category: 'education' },
        //         { name:'základní škola a mateřská škola Elijáš', category: 'education' },
        //         { name:'pavilon Elijáš', category: 'education' },
        //
        //         { name:'Atletický stadion', category: 'sport' },
        //         { name:'Multifunkční víceúčelové hřiště', category: 'sport' },
        //         { name:'Balance Club Brumlovka', category: 'sport' },
        //         { name:'Body Express', category: 'sport' },
        //
        //         { name:'park Brumlovka', category: 'park' },
        //         { name:'Baarův park', category: 'park' },
        //
        //         { name:'dětské hřiště Baarova', category: 'playground' },
        //
        //         { name:'pro automobily', category: 'parking' },
        //         { name:'kola', category: 'parking' },
        //         { name:'motorky', category: 'parking' },
        //         { name:'elektrostanice', category: 'parking' },
        //
        //         { name:'Psí louka', category: 'others' },
        //         { name:'Náměstí Brumlovka', category: 'others' },
        //         { name:'Náměstí E.G.White', category: 'others' },
        //         { name:'Společenské centrum Bethany', category: 'others' },
        //         { name:'Olivka', category: 'others' },
        //         { name:'Umění (Brouk)', category: 'others' },
        //         { name:'Relaxační zóna Želetavská', category: 'others' },
        //         { name:'Vnitroblok budova B', category: 'others' },
        //         { name:'Avatar herna', category: 'others' },
        //     ];
        //     data.forEach(this.fillDB)
        //
        // },
        // async fillDB(item) {
        //     let generatedId = await Building.dispatch('generateId');
        //     await Building.dispatch('createNew', {
        //         id: generatedId,
        //         data: { name: item.name, category: item.category}
        //     });
        // }
    },
    async created() {
        let lc = this.$buefy.loading.open();

        this.generatedId = await Notification.dispatch('generateId');
        this.notificationItem = new Notification();
        this.notificationItem.target[0] = 'building';

        await Enum.fetch();
        await Business.fetch();
        lc.close();
        this.loaded = true;
    },
    mounted() {
        this.refresh++;
    }
}
</script>
