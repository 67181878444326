<template>
    <section class="section">
        <div class="container">
            <h1 class="title is-1">Aktuality</h1>
            <template v-if="this.loaded">
                <validation-observer ref="observer" tag="form" @submit.prevent="submit()">
                    <b-field label="Datum" :label-position="labelPosition">
                        <b-datetimepicker
                            placeholder="Type or select a date..."
                            icon="calendar-today"
                            editable
                            v-model="newsItem.dateToDate"
                        >
                        </b-datetimepicker>
                    </b-field>
<!--                    <b-checkbox v-model="newsItem.published">Publikováno</b-checkbox>-->
                    <upload-component2 :url="newsItem.cover" :model-type="'news'" :image-type="'cover'" :id="actualId" v-model="newsItem.cover"></upload-component2>
                    <b-tabs v-model="activeTab">
                        <template v-for="(tab, indexTab) in tabs">
                            <b-tab-item :key="tab.id" :value="tab.id" :label="tab.label">
                                <b-input-with-validation type="text" rules="required" v-model="newsItem.name[tab.id]" />
                                <b-input-with-validation type="textarea" rules="required" v-model="newsItem.translations[indexTab].description" />
                            </b-tab-item>
                        </template>
                    </b-tabs>
                    <b-field label="Obrázky" :label-position="labelPosition">
                        <div class="box">
                            <div v-for="(item, index) in images" v-bind:key="index">
                                <upload-component2 :url="item" :model-type="'news'" :image-type="'image'" :id="actualId" :comId="index" v-model="images[index]"></upload-component2>
                                <div class="button is-primary" v-on:click="removeImage(index)">Odstranit obrázek</div>
                            </div>
                            <div class="button is-primary" v-on:click="addImage()">Přidat obrázek</div>
                        </div>
                    </b-field>
                    <b-field label="Odrážky" :label-position="labelPosition">
                        <div class="box">
                            <div class="box" v-for="(item, index) in poi" v-bind:key="index">
                                <b-field label="Klíčové slovo" :label-position="labelPosition">
                                    <b-input v-model="poi[index].key" />
                                </b-field>
                                <b-field label="Lokace" :label-position="labelPosition">
                                    <div class="box">
                                        <b-field label="Šířka (Latitude)" :label-position="labelPosition">
                                            <b-input v-model="poi[index].location.lat" />
                                        </b-field>
                                        <b-field label="Délka (Longitude)" :label-position="labelPosition">
                                            <b-input v-model="poi[index].location.lng" />
                                        </b-field>
                                        <b-field label="Název" :label-position="labelPosition">
                                            <b-input v-model="poi[index].location.name" />
                                        </b-field>
                                    </div>
                                </b-field>
                                <b-field label="Poznámka" :label-position="labelPosition">
                                    <b-input v-model="poi[index].note" />
                                </b-field>
                                <b-field label="URL" :label-position="labelPosition">
                                    <b-input v-model="poi[index].url" />
                                </b-field>
                                <div class="button is-primary" v-on:click="removePoi(index)">Odebrat odrážku</div>
                            </div>
                            <div class="button is-primary" v-on:click="addPoi()">Přidat odrážku</div>
                        </div>
                    </b-field>
                    <button class="button is-success">Odeslat</button>
                </validation-observer>
            </template>
        </div>
    </section>
</template>

<script>

import { ValidationObserver } from 'vee-validate';
import BInputWithValidation from '@/form/BInputWithValidation';
import News from "@/store/models/News";
import UploadComponent2 from "@/components/UploadComponent2";
import NewsTranslate from "@/store/models/NewsTranslate";
import Editor from "@/views/Component/Editor";

export default {
    name: 'PageNewsForm',
    components: {
        ValidationObserver,
        BInputWithValidation,
        UploadComponent2,
        Editor
    },
    data() {
        return {
            refresh: 0,
            loaded: false,
            addLoading: false,
            activeTab: 'cs_CZ',
            labelPosition: 'on-border',
            generatedId: '',
            images: [],
            poi: [],
            newsItem: null
        }
    },
    computed: {
        // newsItem() {
        //     if (this.$route.params.id) {
        //         return this.$store.getters["entities/news/query"]()
        //             .with("translations")
        //             .find(this.$route.params.id) || null;
        //     } else {
        //         let n = new News();
        //         let cs = new NewsTranslate();
        //         cs.id = 'cs_CZ';
        //         cs.images = [];
        //         cs.logos = [];
        //         n.translations.push(cs);
        //         let en = new NewsTranslate();
        //         en.id = 'en_US';
        //         en.images = [];
        //         en.logos = [];
        //         n.translations.push(en);
        //
        //         //clear all nested variables manualy? :(
        //         n.name = {
        //             cs_CZ: '',
        //             en_US: ''
        //         };
        //
        //
        //
        //         return n;
        //     }
        // },
        tabs() {
            return [
                {
                    id: 'cs_CZ',
                    label: 'CZ'
                },
                {
                    id: 'en_US',
                    label: 'EN'
                },
            ]
        },
        actualId() {
            if (this.$route.params.id) {
                return this.$route.params.id;
            } else {
                return this.generatedId;
            }
        }
    },
    methods: {
        // TODO: can be adding and removing be better? without loading property?
        addImage() {
            this.addLoading = true;
            this.images.push('');
            this.addLoading = false;
        },
        removeImage(index) {
            this.addLoading = true;
            this.$delete(this.images, index);
            this.addLoading = false;
        },
        addPoi() {
            this.addLoading = true;
            this.poi.push({
                key: '',
                location: {
                    lat: '',
                    lng: '',
                    name: ''
                },
                note: '',
                url: ''
            });
            this.addLoading = false;
        },
        removePoi(index) {
            this.addLoading = true;
            this.$delete(this.poi, index);
            this.addLoading = false;
        },
        async submit() {
            const isValid = await this.$refs.observer.validate();
            if (!isValid) {
                alert(this.$t('form.error'));
            } else {
                let lc = this.$buefy.loading.open();
                if (this.$route.params.id) {
                    // update
                    let translations = [];
                    for (let i in this.newsItem.translations) {
                        this.newsItem.translations[i].images = this.images;
                        this.newsItem.translations[i].poi = this.poi;
                        translations.push(this.newsItem.translations[i].serialized);
                    }
                    await News.update({
                        id: this.$route.params.id,
                        data: this.newsItem.serialized,
                        translations: translations
                    });
                } else {
                    // create
                    //this.newsItem.id = this.generatedId;
                    let translations = [];
                    for (let i in this.newsItem.translations) {
                        this.newsItem.translations[i].images = this.images;
                        this.newsItem.translations[i].poi = this.poi;
                        translations.push(this.newsItem.translations[i].serialized);
                    }
                    let createdId = await News.dispatch('createNew', {
                        id: this.generatedId,
                        data: this.newsItem.serialized,
                        translations: translations
                    });
                    await News.fetch({id: createdId});
                    this.$router.push({name: 'PageNewsForm', params: {id: createdId}});
                }
                lc.close();
            }
        }
    },
    async created() {
        let lc = this.$buefy.loading.open();
        if (this.$route.params.id) {
            await News.fetch({id: this.$route.params.id});
            this.newsItem = this.$store.getters["entities/news/query"]()
                .with("translations")
                .find(this.$route.params.id) || null;
            this.images = this.newsItem.translations[0].images;
            this.poi = this.newsItem.translations[0].poi;
        } else {
            this.generatedId = await News.dispatch('generateId');
            this.newsItem = new News();
            let cs = new NewsTranslate();
            cs.id = 'cs_CZ';
            cs.images = [];
            cs.logos = [];
            this.newsItem.translations.push(cs);
            let en = new NewsTranslate();
            en.id = 'en_US';
            en.images = [];
            en.logos = [];
            this.newsItem.translations.push(en);

            //clear all nested variables manualy? :(
            this.newsItem.name = {
                cs_CZ: '',
                en_US: ''
            };
        }
        lc.close();
        this.loaded = true;
    },
    mounted() {
        this.refresh++;
    }
}
</script>
