<template>
    <section class="section">
        <div class="container">
            <h1 class="title is-1">Parkování</h1>
            <template v-if="this.loaded">
                <validation-observer ref="observer" tag="form" @submit.prevent="submit()">
                    <upload-component2 :url="cover" :model-type="'parking'" :image-type="'cover'" :id="actualId" v-model="cover"></upload-component2>
                    <b-tabs v-model="activeTab">
                        <template v-for="(tab, indexTab) in tabs">
                            <b-tab-item :key="tab.id" :value="tab.id" :label="tab.label">
                                <b-input-with-validation type="text" v-model="parkingItem.translations[indexTab].lot_title" />
                                <b-input-with-validation type="textarea" v-model="parkingItem.translations[indexTab].description" />
                                <b-field label="Ceny" :label-position="labelPosition">
                                    <div class="box">
                                        <div v-for="(price, index) in prices[indexTab]" v-bind:key="index">
                                            <b-field label="Cena" :label-position="labelPosition" class="mt-2">
                                                <div class="box">
                                                    <b-input-with-validation label="Název" :label-position="labelPosition" type="text" v-model="prices[indexTab][index].title" />
                                                    <b-input-with-validation label="Hodnota" :label-position="labelPosition" type="text" v-model="prices[indexTab][index].value" />
                                                    <div class="button is-danger mt-2" v-on:click="removePrices(indexTab, index)">
                                                        <b-icon icon="minus-circle" size="is-small" class="mr-1"></b-icon>Odebrat
                                                    </div>
                                                </div>
                                            </b-field>
                                        </div>
                                        <div class="button is-success mt-2" v-on:click="addPrices(indexTab)">
                                            <b-icon icon="plus-circle" size="is-small" class="mr-1"></b-icon>Přidat
                                        </div>
                                    </div>
                                </b-field>
                                <b-field label="Poznámka" :label-position="labelPosition">
                                    <div class="box">
                                        <b-input-with-validation type="textarea" v-model="parkingItem.translations[indexTab].note" />
                                    </div>
                                </b-field>
                            </b-tab-item>
                        </template>
                    </b-tabs>
                    <button class="button is-success">Odeslat</button>
                </validation-observer>
            </template>
        </div>
    </section>
</template>

<script>

import { ValidationObserver } from 'vee-validate';
import BInputWithValidation from '@/form/BInputWithValidation';
import Parking from "@/store/models/Parking";
import UploadComponent2 from "@/components/UploadComponent2";
import Editor from "@/views/Component/Editor";

export default {
    name: 'PageParkingForm',
    components: {
        ValidationObserver,
        BInputWithValidation,
        UploadComponent2,
        Editor
    },
    data() {
        return {
            refresh: 0,
            loaded: false,
            addLoading: false,
            activeTab: 'cs_CZ',
            labelPosition: 'on-border',
            generatedId: '',
            cover: null,
            prices: [],
            parkingItem: null
        }
    },
    computed: {
        // parkingItem() {
        //     if (this.$route.params.id) {
        //         return this.$store.getters["entities/parking/query"]()
        //             .with("translations")
        //             .find(this.$route.params.id) || null;
        //     } else {
        //         let n = new Parking();
        //         let cs = new ParkingTranslate();
        //         cs.id = 'cs_CZ';
        //         cs.lot_prices = [];
        //         n.translations.push(cs);
        //         let en = new ParkingTranslate();
        //         en.id = 'en_US';
        //         en.lot_prices = [];
        //         n.translations.push(en);
        //
        //         return n;
        //     }
        // },
        tabs() {
            return [
                {
                    id: 'cs_CZ',
                    label: 'CZ'
                },
                {
                    id: 'en_US',
                    label: 'EN'
                },
            ]
        },
        actualId() {
            if (this.$route.params.id) {
                return this.$route.params.id;
            } else {
                return this.generatedId;
            }
        }
    },
    methods: {
        // TODO: can be adding and removing be better? without loading property?
        addPrices(tabId) {
            this.prices[tabId].push({
                title: '',
                value: ''
            });
        },
        removePrices(tabId, index) {
            this.$delete(this.prices[tabId], index);
        },
        async submit() {
            const isValid = await this.$refs.observer.validate();
            if (!isValid) {
                alert(this.$t('form.error'));
            } else {
                let lc = this.$buefy.loading.open();
                if (this.$route.params.id) {
                    // update
                    let translations = [];
                    for (let i in this.parkingItem.translations) {
                        this.parkingItem.translations[i].cover = this.cover;
                        this.parkingItem.translations[i].lot_prices = this.prices[i];
                        translations.push(this.parkingItem.translations[i].serialized);
                    }
                    await Parking.update({
                        id: this.$route.params.id,
                        data: this.parkingItem.serialized,
                        translations: translations
                    });
                } else {
                    // create
                    //this.parkingItem.id = this.generatedId;
                    let translations = [];
                    for (let i in this.parkingItem.translations) {
                        this.parkingItem.translations[i].images = this.images;
                        this.parkingItem.translations[i].poi = this.poi;
                        translations.push(this.parkingItem.translations[i].serialized);
                    }
                    let createdId = await Parking.dispatch('createNew', {
                        id: this.generatedId,
                        data: this.parkingItem.serialized,
                        translations: translations
                    });
                    await Parking.fetch({id: createdId});
                    this.$router.push({name: 'PageParkingForm', params: {id: createdId}});
                }
                lc.close();
            }
        }
    },
    async created() {
        let lc = this.$buefy.loading.open();
        if (this.$route.params.id) {
            await Parking.fetch({id: this.$route.params.id});
            this.parkingItem = this.$store.getters["entities/parking/query"]()
                        .with("translations")
                        .find(this.$route.params.id) || null;
            this.cover = this.parkingItem.translations[0].cover;
            this.prices[0] = this.parkingItem.translations[0].lot_prices;
            this.prices[1] = this.parkingItem.translations[1].lot_prices;
        } else {
            this.generatedId = await Parking.dispatch('generateId');
        }
        lc.close();
        this.loaded = true;
    },
    mounted() {
        this.refresh++;
    }
}
</script>
