import Model from './BaseEntity';
import EventDetail from "@/store/models/EventDetail";
import {myFirebase} from '@/../firebase-service.js';

export default class Event extends Model {

    static entity = 'event';

    static fields() {
        return {
            id: this.attr(''),
            cover: this.string(null).nullable(),
            date: this.attr(myFirebase.firestore.Timestamp.fromDate(new Date())).nullable(),
            name: this.string(null).nullable(),
            owner: this.string(null).nullable(),
            location: this.attr({
                lat: null,
                lng: null,
                name: null
            }),
            state: this.string('new'),
            // message: this.string(null).nullable(),
            publish: this.attr(myFirebase.firestore.Timestamp.fromDate(new Date())).nullable(),
            detail: this.hasOne(EventDetail, 'parent_id')
        }
    }

    get serialized() {
        return {
            cover: this.cover,
            date: this.convertTime(this.date),
            name: this.name,
            owner: this.owner,
            location: this.location,
            state: this.state,
            // message: this.message,
            publish: this.convertTime(this.publish),
        }
    }

    convertTime(value) {
        if (value instanceof Date) {
            return myFirebase.firestore.Timestamp.fromDate(value);
        } else if (value instanceof myFirebase.firestore.Timestamp) {
            return value;
        } else {
            return myFirebase.firestore.Timestamp.fromDate(new Date(value.seconds * 1000));
        }
    }

    get dateToDate () {
        if (this.date) {
            let date = new Date(this.date.seconds * 1000);
            // this.date = myFirebase.firestore.Timestamp.fromDate(date);
            return date;
        } else {
            let date = new Date();
            // this.date = myFirebase.firestore.Timestamp.fromDate(date);
            return date;
        }
    }

    set dateToDate (value) {
        if (value instanceof Date) {
            this.date = myFirebase.firestore.Timestamp.fromDate(value);
        } else {
            return null;
        }
    }

    get publishToDate () {
        if (this.publish) {
            let date = new Date(this.publish.seconds * 1000);
            // this.publish = myFirebase.firestore.Timestamp.fromDate(date);
            return date;
        } else {
            let date = new Date();
            // this.publish = myFirebase.firestore.Timestamp.fromDate(date);
            return date;
        }
    }

    set publishToDate (value) {
        if (value instanceof Date) {
            this.publish = myFirebase.firestore.Timestamp.fromDate(value);
        } else {
            return null;
        }
    }

    get listDate () {
        if (this.date) {
            return new Date(this.date.seconds * 1000);
        } else {
            return new Date();
        }
    }

    get listPublish () {
        if (this.publish) {
            return new Date(this.publish.seconds * 1000);
        } else {
            return new Date();
        }
    }

    get orderby () {
        if (this.date) {
            return this.date.seconds;
        } else {
            return new Date().getTime() / 1000;
        }
    }

}
