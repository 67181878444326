<template>
    <section class="section">
        <div class="container">
            <h1 class="title is-1">Obchody a služby</h1>
            <template v-if="this.loaded">
                <validation-observer ref="observer" tag="form" @submit.prevent="submit()">
                    <div class="block">
                        <b-field label="Kategorie" :label-position="labelPosition">
                            <div class="box">
                                <b-select placeholder="Vyber kategorii" v-model="businessItem.category" @input="checkCategory()">
                                    <option
                                        v-for="option in enums.category"
                                        :value="option"
                                        :key="option">
                                        {{ $t('business.category.' + option) }}
                                    </option>
                                </b-select>
                            </div>
                        </b-field>
                        <b-field v-if="showParking" label="Kategorii pro parkování" :label-position="labelPosition">
                            <div class="box">
                                <b-select placeholder="Vyber kategorii" v-model="businessItem.sub_category">
                                    <option
                                        v-for="option in enums.parking_category"
                                        :value="option"
                                        :key="option">
                                        {{ $t('business.parking.' + option) }}
                                    </option>
                                </b-select>
                            </div>
                        </b-field>
                    </div>
                    <div class="block">
                        <b-field label="Logo" :label-position="labelPosition">
                            <div class="box">
                                <upload-component2 :url="logo" :model-type="'business'" :image-type="'logo'" :id="actualId" v-model="logo"></upload-component2>
                                <div class="button is-danger mt-2" v-on:click="clearLogo()">
                                    <b-icon icon="minus-circle" size="is-small" class="mr-1"></b-icon>Odstranit obrázek
                                </div>
                            </div>
                        </b-field>
                    </div>
                    <div class="block">
                        <b-field label="Úvodní obrázek" :label-position="labelPosition">
                            <div class="box">
                                <upload-component2 :url="cover" :model-type="'business'" :image-type="'cover'" :id="actualId" v-model="cover"></upload-component2>
                                <div class="button is-danger mt-2" v-on:click="clearCover()">
                                    <b-icon icon="minus-circle" size="is-small" class="mr-1"></b-icon>Odstranit obrázek
                                </div>
                            </div>
                        </b-field>
                    </div>
                    <div class="block">
                        <div class="box">
                            <b-tabs v-model="activeTab">
                                <template v-for="(tab, indexTab) in tabs">
                                    <b-tab-item :key="tab.id" :value="tab.id" :label="tab.label">
                                        <b-input-with-validation label="Název" rules="required" type="text" v-model="businessItem.name[tab.id]" />
                                        <b-input-with-validation label="Popis" type="textarea" v-model="businessItem.translations[indexTab].description" />
                                    </b-tab-item>
                                </template>
                            </b-tabs>
                        </div>
                    </div>
                    <div class="block">
                        <b-field label="Denní menu" :label-position="labelPosition">
                            <div class="box">
                                <b-field label="URL" :label-position="labelPosition">
                                    <b-input-with-validation v-model="businessItem.daily_menu" />
                                </b-field>
                            </div>
                        </b-field>
                    </div>
                    <div class="block">
                        <b-field label="Kontakt" :label-position="labelPosition">
                            <div class="box">
                                <b-field label="Adresa" :label-position="labelPosition">
                                    <b-input-with-validation rules="required" v-model="businessItem.contact.address" />
                                </b-field>
                                <b-field label="E-mail" :label-position="labelPosition">
                                    <b-input-with-validation v-model="businessItem.contact.email" />
                                </b-field>
                                <b-field label="Jméno kontaktní osoby" :label-position="labelPosition">
                                    <b-input-with-validation v-model="businessItem.contact.name" />
                                </b-field>
                                <b-field label="Telefon" :label-position="labelPosition">
                                    <b-input-with-validation v-model="businessItem.contact.phone" />
                                </b-field>
                                <b-field label="Web" :label-position="labelPosition">
                                    <b-input-with-validation rules="required" v-model="businessItem.contact.web" />
                                </b-field>
                            </div>
                        </b-field>
                    </div>
                    <div class="block">
                        <b-field label="Otevírací doba" :label-position="labelPosition">
                            <div class="box">
                                <div v-for="(openOne, index) in opens" v-bind:key="index">
                                    <opens v-model="opens[index]"/>
                                    <div class="button is-danger mt-2" v-on:click="removeOpens(index)">
                                        <b-icon icon="minus-circle" size="is-small" class="mr-1"></b-icon>Odebrat
                                    </div>
                                </div>
                                <div class="button is-success mt-2" v-on:click="addOpens()">
                                    <b-icon icon="plus-circle" size="is-small" class="mr-1"></b-icon>Přidat
                                </div>
                            </div>
                        </b-field>
                    </div>
                    <div class="block">
                        <b-field label="Lokace" :label-position="labelPosition">
                            <div class="box">
                                <b-field label="Šířka (Latitude)" :label-position="labelPosition">
                                    <b-input v-model="businessItem.location.lat" />
                                </b-field>
                                <b-field label="Délka (Longitude)" :label-position="labelPosition">
                                    <b-input v-model="businessItem.location.lng" />
                                </b-field>
                                <b-field label="Název" :label-position="labelPosition">
                                    <b-input v-model="businessItem.location.name" />
                                </b-field>
                            </div>
                        </b-field>
                    </div>
                    <div class="block">
                        <b-field label="Obrázky" :label-position="labelPosition">
                            <div class="box">
                                <div v-for="(item, index) in images" v-bind:key="index" class="mb-5 mt-2">
                                    <upload-component2 :url="item" :model-type="'business'" :image-type="'image'" :id="actualId" :countId="index" v-model="images[index]"></upload-component2>
                                    <div class="button is-danger mt-2" v-on:click="removeImage(index)">
                                        <b-icon icon="minus-circle" size="is-small" class="mr-1"></b-icon>Odstranit obrázek
                                    </div>
                                </div>
                                <div class="button is-success my-2" v-on:click="addImage()">
                                    <b-icon icon="plus-circle" size="is-small" class="mr-1"></b-icon>Přidat obrázek
                                </div>
                            </div>
                        </b-field>
                        <button class="button is-success mt-5">
                            <b-icon icon="content-save" size="is-small" class="mr-1"></b-icon>Odeslat
                        </button>
                    </div>
                </validation-observer>
            </template>
        </div>
    </section>
</template>

<script>

import { ValidationObserver } from 'vee-validate';
import BInputWithValidation from '@/form/BInputWithValidation';
import Business from "@/store/models/Business";
import UploadComponent2 from "@/components/UploadComponent2";
import BusinessTranslate from "@/store/models/BusinessTranslate";
import Enum from "@/store/models/Enum";
import Editor from "@/views/Component/Editor";
import Opens from "@/components/Opens";

export default {
    name: 'PageBusinessForm',
    components: {
        Opens,
        ValidationObserver,
        BInputWithValidation,
        UploadComponent2,
        Editor
    },
    data() {
        return {
            loaded: false,
            addLoading: false,
            activeTab: 'cs_CZ',
            labelPosition: 'on-border',
            generatedId: '',
            logo: null,
            cover: null,
            images: [],
            opens: [],
            poi: [],
            showParking: '',
            businessItem: null
        }
    },
    computed: {
        // businessItem() {
        //     if (this.$route.params.id) {
        //         return this.$store.getters["entities/business/query"]()
        //             .with("translations")
        //             .find(this.$route.params.id) || null;
        //     } else {
        //         let b = new Business();
        //         let cs = new BusinessTranslate();
        //         cs.id = 'cs_CZ';
        //         cs.images = [];
        //         cs.opens = [];
        //         b.translations.push(cs);
        //         let en = new BusinessTranslate();
        //         en.id = 'en_US';
        //         en.images = [];
        //         en.opens = [];
        //         b.translations.push(en);
        //
        //         b.contact = {
        //             address: null,
        //             email: null,
        //             name: null,
        //             phone: null,
        //             web: null
        //         };
        //
        //         b.location = {
        //             lat: null,
        //             lng: null,
        //             name: null
        //         };
        //
        //         b.name = {
        //             cs_CZ: null,
        //             en_US: null
        //         };
        //
        //         return b;
        //     }
        // },
        enums() {
            return this.$store.getters["entities/enum/query"]()
                .find('business') || null;
        },
        tabs() {
            return [
                {
                    id: 'cs_CZ',
                    label: 'CZ'
                },
                {
                    id: 'en_US',
                    label: 'EN'
                },
            ]
        },
        actualId() {
            if (this.$route.params.id) {
                return this.$route.params.id;
            } else {
                return this.generatedId;
            }
        }
    },
    methods: {
        // TODO: can be adding and removing be better? without loading property?
        addImage() {
            this.addLoading = true;
            this.images.push('');
            this.addLoading = false;
        },
        removeImage(index) {
            this.addLoading = true;
            this.$delete(this.images, index);
            this.addLoading = false;
        },
        clearLogo() {
            this.logo = null;
            // this.$forceUpdate();
        },
        clearCover() {
            this.cover = null;
            // this.$forceUpdate();
        },
        addOpens() {
            this.addLoading = true;
            this.opens.push({
                day: '',
                value: ''
            });
            this.addLoading = false;
        },
        removeOpens(index) {
            this.addLoading = true;
            this.$delete(this.opens, index);
            this.addLoading = false;
        },
        async submit() {
            const isValid = await this.$refs.observer.validate();
            if (!isValid) {
                alert(this.$t('form.error'));
            } else {
                let lc = this.$buefy.loading.open();
                if (this.$route.params.id) {
                    // update
                    let translations = [];
                    if (this.businessItem.daily_menu === '') {
                        this.businessItem.daily_menu = null;
                    }
                    for (let i in this.businessItem.translations) {
                        this.businessItem.logo = this.logo;
                        this.businessItem.cover = this.cover;
                        this.businessItem.translations[i].images = this.images;
                        this.businessItem.translations[i].opens = this.opens;
                        translations.push(this.businessItem.translations[i].serialized);
                    }
                    await Business.update({
                        id: this.$route.params.id,
                        data: this.businessItem.serialized,
                        translations: translations
                    });
                } else {
                    // create
                    let translations = [];
                    for (let i in this.businessItem.translations) {
                        this.businessItem.logo = this.logo;
                        this.businessItem.cover = this.cover;
                        this.businessItem.translations[i].images = this.images;
                        this.businessItem.translations[i].opens = this.opens;
                        translations.push(this.businessItem.translations[i].serialized);
                    }
                    let createdId = await Business.dispatch('createNew', {
                        id: this.generatedId,
                        data: this.businessItem.serialized,
                        translations: translations
                    });
                    await Business.fetch({id: createdId});
                    this.$router.push({name: 'PageBusinessForm', params: {id: createdId}});
                }
                lc.close();
            }
        },
        checkCategory() {
            if (this.businessItem.category === 'parking') {
                this.showParking = true;
            } else {
                this.showParking = false;
                this.businessItem.sub_category = null;
            }
        }
    },
    async created() {
        let lc = this.$buefy.loading.open();
        if (this.$route.params.id) {
            await Business.fetch({id: this.$route.params.id});
            this.businessItem = this.$store.getters["entities/business/query"]()
                .with("translations")
                .find(this.$route.params.id) || null;
            this.logo = this.businessItem.logo;
            this.cover = this.businessItem.cover;
            this.images = this.businessItem.translations[0].images;
            this.opens = this.businessItem.translations[0].opens;
            this.poi = this.businessItem.translations[0].poi;
            if (this.businessItem.category === 'parking') {
                this.showParking = true;
            }
        } else {
            this.generatedId = await Business.dispatch('generateId');
            this.businessItem = new Business();
            let cs = new BusinessTranslate();
            cs.id = 'cs_CZ';
            cs.images = [];
            cs.opens = [];
            this.businessItem.translations.push(cs);
            let en = new BusinessTranslate();
            en.id = 'en_US';
            en.images = [];
            en.opens = [];
            this.businessItem.translations.push(en);

            this.businessItem.contact = {
                address: null,
                email: null,
                name: null,
                phone: null,
                web: null
            };

            this.businessItem.location = {
                lat: null,
                lng: null,
                name: null
            };

            this.businessItem.name = {
                cs_CZ: null,
                en_US: null
            };

        }
        await Enum.fetch();
        lc.close();
        this.loaded = true;
    }
}
</script>
