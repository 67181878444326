<template>
    <section class="section">
        <div class="container">
            <h1 class="title is-1">Pronájmy</h1>
            <template v-if="this.loaded">
                <validation-observer ref="observer" tag="form" @submit.prevent="submit()">
                    <div class="block">
                        <b-field label="Kategorie" :label-position="labelPosition">
                            <div class="box">
                                <b-select placeholder="Vyber kategorii" v-model="realEstateItem.category">
                                    <option
                                        v-for="option in enums.category"
                                        :value="option"
                                        :key="option">
                                        {{ $t('realestate.category.' + option) }}
                                    </option>
                                </b-select>
                            </div>
                        </b-field>
                    </div>
                    <div class="block">
                        <b-field label="Image" :label-position="labelPosition">
                            <div class="box">
                                <upload-component2 :url="realEstateItem.cover" :model-type="'realestate'" :image-type="'cover'" :id="actualId" v-model="realEstateItem.cover"></upload-component2>
                            </div>
                        </b-field>
                    </div>
                    <div class="block">
                        <b-field label="Info" :label-position="labelPosition">
                            <div class="box">
                                <b-field label="Oblast" :label-position="labelPosition">
                                    <b-input-with-validation type="text"
                                                             v-model="realEstateItem.area"/>
                                </b-field>
                                <b-field label="E-mail" :label-position="labelPosition">
                                    <b-input-with-validation type="text" rules="required" v-model="realEstateItem.email"/>
                                </b-field>
                                <b-field label="Ulice" :label-position="labelPosition">
                                    <b-input-with-validation type="text" rules="required"
                                                             v-model="realEstateItem.street"/>
                                </b-field>
                                <b-tabs v-model="activeTab">
                                    <template v-for="tab in tabs">
                                        <b-tab-item :key="tab.id" :value="tab.id" :label="tab.label">
                                            <b-field label="Město" :label-position="labelPosition">
                                                <b-input-with-validation type="text" rules="required" v-model="realEstateItem.city[tab.id]"/>
                                            </b-field>
                                            <b-field label="Poznámka" :label-position="labelPosition">
                                                <b-input-with-validation type="text" v-model="realEstateItem.note[tab.id]"/>
                                            </b-field>
                                            <b-field label="Název" :label-position="labelPosition">
                                                <b-input-with-validation type="text" rules="required" v-model="realEstateItem.title[tab.id]"/>
                                            </b-field>
                                            <b-field label="URL" :label-position="labelPosition">
                                                <b-input-with-validation type="text" rules="required" v-model="realEstateItem.url[tab.id]"/>
                                            </b-field>
                                        </b-tab-item>
                                    </template>
                                </b-tabs>
                            </div>
                        </b-field>
                    </div>
                    <button class="button is-success">Odeslat</button>
                </validation-observer>
            </template>
        </div>
    </section>
</template>

<script>

import { ValidationObserver } from 'vee-validate';
import BInputWithValidation from '@/form/BInputWithValidation';
import RealEstate from "@/store/models/RealEstate";
import UploadComponent2 from "@/components/UploadComponent2";
import Editor from "@/views/Component/Editor";
import Enum from "@/store/models/Enum";

export default {
    name: 'PageRealEstateForm',
    components: {
        ValidationObserver,
        BInputWithValidation,
        UploadComponent2,
        Editor
    },
    data() {
        return {
            refresh: 0,
            loaded: false,
            addLoading: false,
            activeTab: 'cs_CZ',
            labelPosition: 'on-border',
            generatedId: '',
            images: [],
            poi: [],
            realEstateItem: null
        }
    },
    computed: {
        // realEstateItem() {
        //     if (this.$route.params.id) {
        //         return this.$store.getters["entities/realestate/query"]()
        //             .find(this.$route.params.id) || null;
        //     } else {
        //         let n = new RealEstate();
        //         //clear all variable manualy? :(
        //
        //         return n;
        //     }
        // },
        tabs() {
            return [
                {
                    id: 'cs_CZ',
                    label: 'CZ'
                },
                {
                    id: 'en_US',
                    label: 'EN'
                },
            ]
        },
        enums() {
            return this.$store.getters["entities/enum/query"]()
                .find('real_estate') || null;
        },
        actualId() {
            if (this.$route.params.id) {
                return this.$route.params.id;
            } else {
                return this.generatedId;
            }
        }
    },
    methods: {
        // TODO: can be adding and removing be better? without loading property?
        async submit() {
            const isValid = await this.$refs.observer.validate();
            if (!isValid) {
                alert(this.$t('form.error'));
            } else {
                let lc = this.$buefy.loading.open();
                if (this.$route.params.id) {
                    // update
                    await RealEstate.update({id: this.$route.params.id, data: this.realEstateItem.serialized});
                } else {
                    // create
                    let createdId = await RealEstate.dispatch('createNew', {
                        id: this.generatedId,
                        data: this.realEstateItem.serialized
                    });
                    await RealEstate.fetch({id: createdId});
                    this.$router.push({name: 'PageRealEstateForm', params: {id: createdId}});
                }
                lc.close();
            }
        }
    },
    async created() {
        let lc = this.$buefy.loading.open();
        if (this.$route.params.id) {
            await RealEstate.fetch();
            this.realEstateItem = this.$store.getters["entities/realestate/query"]()
                .find(this.$route.params.id) || null;
            // if (typeof this.realEstateItem.title !== 'object') {
            //     let title = {
            //         cs_CZ: this.realEstateItem.title,
            //         en_US: this.realEstateItem.title
            //     };
            //     let note = {
            //         cs_CZ: this.realEstateItem.note,
            //         en_US: this.realEstateItem.note
            //     };
            //     let city = {
            //         cs_CZ: this.realEstateItem.city,
            //         en_US: this.realEstateItem.city
            //     };
            //     let url = {
            //         cs_CZ: this.realEstateItem.url,
            //         en_US: this.realEstateItem.url
            //     };
            //     this.realEstateItem.title = title;
            //     this.realEstateItem.note = note;
            //     this.realEstateItem.city = city;
            //     this.realEstateItem.url = url;
            // }
        } else {
            this.generatedId = await RealEstate.dispatch('generateId');
            this.realEstateItem = new RealEstate();
        }
        await Enum.fetch();
        lc.close();
        this.loaded = true;
    },
    mounted() {
        this.refresh++;
    }
}
</script>
