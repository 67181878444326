import * as api from '../../../firebase-service.js';

export default {
    namespaced: true,
    actions: {
        fetch(context) {
            return api.getUserInfo().then(usersinfo => {
                return context.dispatch('create', { data: usersinfo })
            })
        }
    }
}
