<template>
    <div>
        <ckeditor :editor="editor" v-model="editorData" @input="onEditorInput" :config="editorConfig"/>
    </div>
</template>

<script>
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

export default {
    components: {

    },

    props: {
        value: {
            type: String,
            default: '',
        },
    },

    data() {
        return {
            editor: ClassicEditor,
            editorData: this.value,
            editorConfig: {
                toolbar: {
                    items: [
                        'heading',
                        '|',
                        'bold',
                        'italic',
                        'link',
                        'bulletedList',
                        'numberedList',
                        'uploadImage',
                        'blockQuote',
                        'undo',
                        'redo'
                    ]
                },
                image: {
                    toolbar: [
                        'imageStyle:full',
                        'imageStyle:side',
                        '|',
                        'imageTextAlternative'
                    ]
                },
                language: 'cs'
            }
        }
    },

    methods: {
        onEditorInput(data) {
            this.$emit('input', data)
        }
    }
}
</script>
