import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import VueI18n from 'vue-i18n';
import { CZECH_TRANSLATIONS } from './translations/cs';

import VueFilterDateFormat from '@vuejs-community/vue-filter-date-format';
Vue.use(VueFilterDateFormat);

import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use(CKEditor);

import Buefy from 'buefy';
import 'buefy/dist/buefy.css';

Vue.config.productionTip = false;

Vue.use(Buefy);
Vue.use(VueI18n);

const TRANSLATIONS = {
  cs: CZECH_TRANSLATIONS
};
const i18n = new VueI18n({
  locale: 'cs',
  messages: TRANSLATIONS,
});

new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
