import { Model as ORMModel } from "@vuex-orm/core";

export default class Model extends ORMModel {

    /**
     * Load records from server
     * @param filter id or filter object
     * @param bypassCache
     */
    static async fetch(filter, bypassCache = false) {
        if (typeof filter !== "object") filter = { id: filter };
        return this.dispatch("fetch", { filter, bypassCache });
    }

}
