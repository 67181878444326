import Model from './BaseEntity';

export default class RealEstate extends Model {

    static entity = 'building';

    static fields() {
        return {
            id: this.attr(''),
            category: this.string(''),
            name: this.string(''),
        }
    }

    get serialized() {
        return {
            category: this.category,
            name: this.name,
        }
    }

}
