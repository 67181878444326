export const CZECH_TRANSLATIONS = {
    business: {
        category: {
            restaurant: 'Restaurace',
            cafe: 'Kavárna',
            grocery: 'Obchody',
            service: 'Služby',
            bank: 'Bankovnictví',
            health: 'Zdravotnictví',
            sport: 'Sport',
            education: 'Vzdělávání',
            parking: 'Parkování',
            playground: 'Hřiště',
            office: 'Kanceláře',
            culture: 'Kultura',
            stops: 'Zastávky'
        },
        parking: {
            car: 'Auta',
            motorcycle: 'Motorky a kola',
            ev: 'Elektrostanice'
        }
    },
    event: {
        state: {
            new: 'Nová',
            public: 'Zveřejněná',
            denied: 'Zamítnutá',
            hidden: 'Skrytá'
        },
        registration: {
            free: 'zdarma',
            inApp: 'v aplikaci',
            contact: 'kontakt'
        }
    },
    building: {
        category: {
            admin: 'Administrativní budovy',
            education: 'Vzdělání',
            others: 'Ostatní',
            park: 'Parky',
            parking: 'Veřejný parking',
            playground: 'Dětské hřiště',
            residence: 'Rezidenční budovy',
            service: 'Služby',
            sport: 'Sportoviště'
        }
    },
    realestate: {
        category: {
            office: 'Kanceláře',
            business: 'Obchody',
            flat: 'Byty',
            flexi_office: 'Fleksibilní kanceláře a coworking',
            sc_bettany: 'Společenské centrum Bethany',
            stadium: 'Stadión',
            sport_area: 'Sportoviště',
            public_place: 'Veřejný prostor',
            others: 'Ostatní'
        }
    },
    form: {
        error: 'Data NEBYLA uložena. Formulář neprošel validací. Prosím zkontrolujte zadaná data.'
    }
};
