import Model from './BaseEntity';

export default class NewsTranslate extends Model {

    static entity = 'newstranslate';

    static fields() {
        return {
            id: this.attr(''),
            parent_id: this.attr(null).nullable(),
            description: this.string(null).nullable(),
            images: this.attr([]),
            poi: this.attr([])
        }
    }

    get serialized() {
        return {
            id: this.id,
            description: this.description,
            images: this.images,
            poi: this.poi
        }
    }

}
