<template>
    <div>
        <b-field>
            <b-select placeholder="Day From" v-model="dayFrom" @input="updateData()">
                <option :value="0">Pondělí</option>
                <option :value="1">Úterý</option>
                <option :value="2">Středa</option>
                <option :value="3">Čtvrtek</option>
                <option :value="4">Pátek</option>
                <option :value="5">Sobota</option>
                <option :value="6">Neděle</option>
            </b-select>
            <p class="control">
                <b-button>-></b-button>
            </p>
            <b-select placeholder="Day From" v-model="dayTo" @input="updateData()">
                <option :value="0">Pondělí</option>
                <option :value="1">Úterý</option>
                <option :value="2">Středa</option>
                <option :value="3">Čtvrtek</option>
                <option :value="4">Pátek</option>
                <option :value="5">Sobota</option>
                <option :value="6">Neděle</option>
            </b-select>
            <b-input type="text" placeholder="10:00 - 16:00" v-model="text" @input="updateData()"></b-input>
        </b-field>
    </div>
</template>

<script>

export default {
    name: 'Opens',
    props: ['value'],
    data() {
        return {
            dayFrom: '',
            dayTo: '',
            day: '',
            text: ''
        }
    },
    mounted() {
        if (this.value) {
            if (this.value.day) {
                let days = this.value.day.split('-');
                this.dayFrom = days[0];
                if (days[1]) {
                    this.dayTo = days[1];
                }
            }
            if (this.value.value) {
                this.text = this.value.value;
            }
        }
    },
    methods: {
        updateData() {
            let data = {
                day: this.dayFrom + ( (this.dayTo && this.dayFrom != this.dayTo) ? '-' + this.dayTo : '' )   ,
                value: this.text
            };
            this.$emit("input", data);
        },
    }
}
</script>
