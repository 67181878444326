import Model from './BaseEntity';
import {myFirebase} from "../../../firebase-service";

export default class Notification extends Model {

    static entity = 'notification';

    static fields() {
        return {
            id: this.attr(''),
            title: this.string(null).nullable(),
            message: this.string(null).nullable(),
            description: this.string(null).nullable(),
            target: this.attr(['building', '']),
            timestamp: this.attr(null),
        }
    }

    get serialized() {
        return {
            title: this.title,
            message: this.message,
            description: this.description,
            target: this.target,
            timestamp: myFirebase.firestore.Timestamp.fromDate(new Date()),
        }
    }

    get timestampDate () {
        if (this.timestamp) {
            return new Date(this.timestamp.seconds * 1000);
        } else {
            return new Date();
        }
    }

    get orderby () {
        if (this.timestamp) {
            return this.timestamp.seconds;
        } else {
            return new Date().getTime() / 1000;
        }
    }

}
