import Model from './BaseEntity';
import UserInfo from './UserInfo';

export default class User extends Model {

    static entity = 'user';

    static fields() {
        return {
            id: this.attr(''),
            userinfo: this.hasOne(UserInfo, 'id', 'id')
        }
    }

}
