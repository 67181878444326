import { Model } from "@vuex-orm/core";

export default class LoggedUser extends Model {

  static entity = 'loggedUser';

  static fields() {
    return {
      id: this.attr(''),
      email: this.string(''),
    }
  }

}
