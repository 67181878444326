import Model from './BaseEntity';

export default class UserInfo extends Model {

    static entity = 'userinfo';

    static fields() {
        return {
            id: this.attr(''),
            name: this.string(''),
            email: this.string(''),
            phone: this.string(''),
            image: this.string(''),
        }
    }

}
