import Model from './BaseEntity';
import {myFirebase} from "../../../firebase-service";

export default class RealEstate extends Model {

    static entity = 'realestate';

    static fields() {
        return {
            id: this.attr(''),
            cover: this.string(''),
            area: this.string(''),
            category: this.string(''),
            city: this.attr({
                cs_CZ: '',
                en_US: ''
            }),
            note: this.attr({
                cs_CZ: '',
                en_US: ''
            }),
            street: this.string(''),
            title: this.attr({
                cs_CZ: '',
                en_US: ''
            }),
            url: this.attr({
                cs_CZ: '',
                en_US: ''
            }),
            email: this.string(''),
            state: this.string('public'),
            timestamp: this.attr(null),
        }
    }

    get serialized() {
        return {
            cover: this.cover,
            area: this.area,
            category: this.category,
            city: this.city,
            note: this.note,
            street: this.street,
            title: this.title,
            url: this.url,
            email: this.email,
            state: this.state,
            timestamp: myFirebase.firestore.Timestamp.fromDate(new Date()),
        }
    }

}
