<template>
    <section class="section">
        <div class="block is-flex is-justify-content-flex-end">
            <b-button :to="{ name: 'PageParkingForm' }" tag="router-link" class="is-success">
                <b-icon icon="plus-circle" size="is-small" class="mr-1"></b-icon>Vytvořit aktualitu
            </b-button>
        </div>
        <b-table
            :data="this.parking"
            :per-page="perPage"
            :paginated="true"
            :current-page.sync="currentPage"
            :pagination-simple="true"
            :pagination-position="'bottom'"
            default-sort="name"
        >
            <b-table-column field="name" label="Název" v-slot="props">
                {{ props.row.name }}
            </b-table-column>
            <b-table-column field="action" label="" v-slot="props">
                <div class="buttons">
                    <b-button v-on:click="edit(props.row.id)">
                        <b-icon icon="circle-edit-outline" size="is-small" class="mr-1"></b-icon>Upravit
                    </b-button>
                </div>
            </b-table-column>
        </b-table>
    </section>
</template>

<script>
    import Parking from '@/store/models/Parking';

    export default {
        name: 'PageParking',
        data() {
            return {
                currentPage: 1,
                perPage: 15
            }
        },
        computed: {
            parking() {
                return this.$store.getters['entities/parking/query']().withAll().get();
            }
        },
        methods: {
            edit(id) {
                this.$router.push({name: 'PageParkingForm', params: { id } });
            }
        },
        async created() {
            let lc = this.$buefy.loading.open();
            await Parking.fetch();
            lc.close();
        }
    }
</script>
