<template>
    <b-navbar>
        <template #brand>
            <b-navbar-item>
                <img src="https://www.bbcentrum.cz/images/90x49_logo_bbcentrum.svg">
            </b-navbar-item>
        </template>
        <template #start>
            <template v-if="user !== null">
                <b-navbar-item tag="router-link" :to="{ name: 'PageDashboard' }">
                    Úvod
                </b-navbar-item>
                <b-navbar-item tag="router-link" :to="{ name: 'PageNewsList' }">
                    Aktuality
                </b-navbar-item>
                <b-navbar-item tag="router-link" :to="{ name: 'PageBusinessList' }">
                    Obchody a služby
                </b-navbar-item>
                <b-navbar-item tag="router-link" :to="{ name: 'PageEventList' }">
                    Akce
                </b-navbar-item>
                <b-navbar-item tag="router-link" :to="{ name: 'PageRealEstateList' }">
                    Pronájmy
                </b-navbar-item>
                <b-navbar-item tag="router-link" :to="{ name: 'PageParkingList' }">
                    Parkování
                </b-navbar-item>
                <b-navbar-item tag="router-link" :to="{ name: 'PageNotificationList' }">
                    Upozornění
                </b-navbar-item>
                <b-navbar-item tag="router-link" :to="{ name: 'PageUserList' }">
                    Uživatelé
                </b-navbar-item>
            </template>
        </template>
        <template #end>
            <b-navbar-item v-if="user">
                {{ user.email }}
            </b-navbar-item>
            <b-navbar-item v-on:click="logout()" v-if="user">
                Logout
            </b-navbar-item>
        </template>
    </b-navbar>
</template>

<script>

    import firebase from 'firebase';
    import LoggedUser from '@/store/models/LoggedUser';

    export default {
        name: 'ComponentNavigation',
        computed: {
            user() {
                return LoggedUser.query().first();
            }
        },
        methods: {
            logout() {
                firebase.auth().signOut().then(() => {
                    LoggedUser.deleteAll();
                    //this.$router.push({ name: 'PageLogin' });
                });
            }
        }
    }
</script>
