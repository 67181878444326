<template>
    <section class="section">
        <div class="container">
            <h1 class="title is-1">Akce</h1>
            <template v-if="this.loaded">
                <validation-observer ref="observer" tag="form" @submit.prevent="submit()">
                    <div class="box">
                        <b-field label="Stav" :label-position="labelPosition">
                            <div class="box">
                                <b-select placeholder="Vyber stav" v-model="eventItem.state">
                                    <option
                                        v-for="option in enums.state"
                                        :value="option"
                                        :key="option">
                                        {{ $t('event.state.' + option) }}
                                    </option>
                                </b-select>
                                <b-field label="Poznámka uživateli" :label-position="labelPosition" class="mt-3">
                                    <b-input v-model="message" />
                                </b-field>
                            </div>
                        </b-field>
                    </div>
                    <div class="box">
                        <b-field label="Začátek akce" :label-position="labelPosition">
                            <b-datetimepicker
                                placeholder="Type or select a date..."
                                icon="calendar-today"
                                editable
                                v-model="eventItem.dateToDate"
                            >
                            </b-datetimepicker>
                        </b-field>
                        <b-field label="Název" :label-position="labelPosition">
                            <b-input-with-validation rules="required" v-model="eventItem.name" />
                        </b-field>
                        <div class="block">
                            <b-field label="Úvodní obrázek" :label-position="labelPosition">
                                <div class="box">
                                    <upload-component2 :url="eventItem.cover" :model-type="'event'" :image-type="'cover'" :id="actualId" v-model="eventItem.cover"></upload-component2>
                                    <div class="button is-danger mt-2" v-on:click="clearCover()">
                                        <b-icon icon="minus-circle" size="is-small" class="mr-1"></b-icon>Odstranit
                                    </div>
                                </div>
                            </b-field>
                        </div>

                        <b-field label="Lokace" :label-position="labelPosition">
                            <div class="box">
                                <b-field label="Šířka (Latitude)" :label-position="labelPosition">
                                    <b-input-with-validation v-model="eventItem.location.lat" />
                                </b-field>
                                <b-field label="Délka (Longitude)" :label-position="labelPosition">
                                    <b-input-with-validation v-model="eventItem.location.lng" />
                                </b-field>
                                <b-field label="Název" :label-position="labelPosition">
                                    <b-input-with-validation rules="required" v-model="eventItem.location.name" />
                                </b-field>
                            </div>
                        </b-field>

                        <b-field label="Zobrazit od" :label-position="labelPosition">
                            <b-datetimepicker
                                placeholder="vyber datum..."
                                icon="calendar-today"
                                editable
                                v-model="eventItem.publishToDate"
                            >
                            </b-datetimepicker>
                        </b-field>
                    </div>
                    <div class="box">
                        <b-field label="Kontakt" :label-position="labelPosition">
                            <div class="box">
                                <b-field label="E-mail" :label-position="labelPosition">
                                    <b-input-with-validation v-model="eventItem.detail.contact.email" />
                                </b-field>
                                <b-field label="Jméno kontaktní osoby" :label-position="labelPosition">
                                    <b-input-with-validation v-model="eventItem.detail.contact.name" />
                                </b-field>
                                <b-field label="Telefon" :label-position="labelPosition">
                                    <b-input-with-validation v-model="eventItem.detail.contact.phone" />
                                </b-field>

                                <upload-component2 :url="eventItem.detail.contact.image" :model-type="'event'" :image-type="'image'" :id="actualId" :countId="'contact'" v-model="eventItem.detail.contact.image"></upload-component2>
                                <div class="button is-danger mt-2" v-on:click="clearContactImage()">
                                    <b-icon icon="minus-circle" size="is-small" class="mr-1"></b-icon>Odstranit Obrázek
                                </div>

                            </div>
                        </b-field>
                        <b-field label="Popis" :label-position="labelPosition">
                            <editor v-model="eventItem.detail.description" />
                        </b-field>
                        <b-field label="Obrázky" :label-position="labelPosition">
                            <div class="box">
                                <div v-for="(item, index) in images" v-bind:key="index">
                                    <upload-component2 :url="item" :model-type="'event'" :image-type="'image'" :id="actualId" :countId="'i' + index" v-model="images[index]"></upload-component2>
                                    <div class="button is-danger mt-2" v-on:click="removeImage(index)">
                                        <b-icon icon="minus-circle" size="is-small" class="mr-1"></b-icon>Odstranit obrázek
                                    </div>
                                </div>
                                <div class="button is-primary" v-on:click="addImage()">Pŕidat obrázek</div>
                            </div>
                        </b-field>
                        <b-field label="Loga" :label-position="labelPosition">
                            <div class="box">
                                <div v-for="(item, index) in logos" v-bind:key="index">
                                    <upload-component2 :url="item" :model-type="'event'" :image-type="'image'" :id="actualId" :countId="'l' + index" v-model="logos[index]"></upload-component2>
                                    <div class="button is-danger mt-2" v-on:click="removeLogo(index)">
                                        <b-icon icon="minus-circle" size="is-small" class="mr-1"></b-icon>Odstranit logo
                                    </div>
                                </div>
                                <div class="button is-primary" v-on:click="addLogo()">Přidat logo</div>
                            </div>
                        </b-field>
                        <b-field label="Registrace" :label-position="labelPosition">
                            <div class="box">
                                <b-field label="Poznámka" :label-position="labelPosition">
                                    <b-input v-model="eventItem.detail.registration.note" />
                                </b-field>
                                <b-field label="Cena" :label-position="labelPosition">
                                    <b-input v-model="eventItem.detail.registration.price" />
                                </b-field>
                                <b-select placeholder="Typ" v-model="eventItem.detail.registration.type">
                                    <option
                                        v-for="option in enums.registration"
                                        :value="option"
                                        :key="option">
                                        {{ $t('event.registration.' + option) }}
                                    </option>
                                </b-select>
                            </div>
                        </b-field>
                    </div>
                    <button class="button is-success">Odeslat</button>
                </validation-observer>
            </template>
        </div>
    </section>
</template>

<script>

    import { ValidationObserver } from 'vee-validate';
    import BInputWithValidation from '@/form/BInputWithValidation';
    import Event from "@/store/models/Event";
    import EventDetail from "@/store/models/EventDetail";
    import UploadComponent2 from "@/components/UploadComponent2";
    import Enum from "@/store/models/Enum";
    import Notification from '@/store/models/Notification';
    //import {myFirebase} from '../../../firebase-service.js';
    import Editor from "@/views/Component/Editor";

    export default {
        name: 'PageEventForm',
        components: {
            ValidationObserver,
            BInputWithValidation,
            UploadComponent2,
            Editor
        },
        data() {
            return {
                loaded: false,
                addLoading: false,
                generatedId: '',
                labelPosition: 'on-border',
                images: [],
                logos: [],
                eventItem: null,
                message: '',
                oldState: 'new'
            }
        },
        computed: {
            // eventItem() {
            //     if (this.$route.params.id) {
            //         return this.$store.getters["entities/event/query"]()
            //             .with("detail")
            //             .find(this.$route.params.id) || null;
            //     } else {
            //         let event = new Event();
            //         let detail = new EventDetail();
            //         detail.id = 'detail';
            //         event.detail = detail;
            //
            //         //clear all nested variables manualy? :(
            //         event.location = {
            //             lat: null,
            //             lng: null,
            //             name: null
            //         };
            //
            //         event.detail.contact = {
            //             address: null,
            //             email: null,
            //             name: null,
            //             phone: null,
            //             web: null
            //         };
            //
            //         event.detail.registration = {
            //             note: null,
            //             price: null,
            //             type: null
            //         };
            //
            //         event.detail.images = [];
            //         event.detail.logos = [];
            //
            //         return event;
            //     }
            // },
            enums() {
                return this.$store.getters["entities/enum/query"]()
                    .find('event') || null;
            },
            actualId() {
                if (this.$route.params.id) {
                    return this.$route.params.id;
                } else {
                    return this.generatedId;
                }
            }
        },
        methods: {
            // TODO: can be adding and removing be better? without loading property?
            addImage() {
                this.images.push('');
            },
            removeImage(index) {
                this.$delete(this.images, index);
            },
            addLogo() {
                this.logos.push('');
            },
            removeLogo(index) {
                this.$delete(this.logos, index);
            },
            clearLogo() {
                this.eventItem.logo = '';
                this.$forceUpdate();
            },
            clearCover() {
                this.eventItem.cover = '';
                this.$forceUpdate();
            },
            clearContactImage() {
                this.eventItem.detail.contact.image = '';
                this.$forceUpdate();
            },
            async update() {
                let lc = this.$buefy.loading.open();
                if (this.$route.params.id) {
                    // update
                    await Event.update({ id: this.$route.params.id, data: this.eventItem.serialized });
                }
                lc.close();
            },
            async submit() {
                const isValid = await this.$refs.observer.validate();
                if (!isValid) {
                    alert(this.$t('form.error'));
                } else {
                    let lc = this.$buefy.loading.open();
                    if (this.$route.params.id) {
                        this.eventItem.detail.images = this.images;
                        this.eventItem.detail.logos = this.logos;
                        this.eventItem.message = this.message;
                        await Event.update({
                            id: this.$route.params.id,
                            data: this.eventItem.serialized,
                            translations: [this.eventItem.detail.serialized]
                        });
                        this.sendNotification();
                    } else {
                        // create
                        this.eventItem.detail.images = this.images;
                        this.eventItem.detail.logos = this.logos;
                        let createdId = await Event.dispatch('createNew', {
                            id: this.generatedId,
                            data: this.eventItem.serialized,
                            translations: [this.eventItem.detail.serialized]
                        });
                        await Event.fetch({id: createdId});
                        this.$router.push({name: 'PageEventForm', params: {id: createdId}});
                    }
                    lc.close();
                }
            },
            async sendNotification() {

                let notification = new Notification();
                notification.target[0] = 'user';
                notification.target[1] = this.eventItem.owner;
                notification.title = this.eventItem.name;
                let message = '';
                if (this.oldState !== this.eventItem.state) {
                    if (this.eventItem.state === 'public') {
                        message = 'Schváleno';
                    }
                }

                if (this.message.trim().length > 0) {
                    if (message.length > 0) {
                        message = message + ' ' ;
                    }
                    message = message + 'Poznámka: ' + this.message;
                }

                if (message.trim().length > 0) {
                    notification.message = message;
                    let generatedIdMessage = await Notification.dispatch('generateId');
                    await Notification.dispatch('createNew', {
                        id: generatedIdMessage,
                        data: notification.serialized
                    });
                }
                this.message = '';
            }
        },
        async created() {
            let lc = this.$buefy.loading.open();
            if (this.$route.params.id) {
                await Event.fetch({id: this.$route.params.id});
                this.eventItem = this.$store.getters["entities/event/query"]()
                                .with("detail")
                                .find(this.$route.params.id) || null;
                this.images = this.eventItem.detail.images;
                this.logos = this.eventItem.detail.logos;
                this.oldState = this.eventItem.state + '';

                //fix contact image if not exist
                if (this.eventItem.detail.contact.image === undefined) {
                    this.eventItem.detail.contact.image = null;
                }

            } else {
                this.generatedId = await Event.dispatch('generateId');
                this.eventItem = new Event();
                let detail = new EventDetail();
                detail.id = 'detail';
                this.eventItem.detail = detail;

                //clear all nested variables manualy? :(
                this.eventItem.location = {
                    lat: null,
                    lng: null,
                    name: null
                };

                this.eventItem.detail.contact = {
                    email: null,
                    name: null,
                    phone: null,
                    image: null
                };

                this.eventItem.detail.registration = {
                    note: null,
                    price: null,
                    type: null
                };

                this.eventItem.detail.images = [];
                this.eventItem.detail.logos = [];
            }
            await Enum.fetch();
            lc.close();
            this.loaded = true;
        }
    }
</script>
