<template>
    <div>
        <section v-if="this.uploading">
            <b-progress
                v-if="uploading && !uploadEnd"
                :value="progressUpload"
                show-value
                format="percent"
            ></b-progress>
        </section>
        <div v-if="thumbnail !== null && thumbnail.length > 0">
            <img :src="thumbnail">
        </div>
        <div v-else>NO IMAGE</div>
        <template v-if="url === null || uploadStep !== null">
            <div>
                <div v-if="uploadStep !== null && !this.uploading">
                    <h2 class="my-2">Nahrát soubor (max 800kB)</h2>
                    <div v-if="uploadStep === 'edit'">
                        <b-field class="file is-primary" :class="{'has-name': !!file}">
<!--                            <b-upload v-model="file" @input="upload" class="file-label" accept="image/*" :multiple="false">-->
<!--                                <span class="file-cta">-->
<!--                                    <b-icon class="file-icon" icon="upload"></b-icon>-->
<!--                                    <span class="file-label">Klikni pro nahrátí obrázku</span>-->
<!--                                </span>-->
<!--                                <span class="file-name" v-if="file">-->
<!--                                    {{ file.name }}-->
<!--                                </span>-->
<!--                            </b-upload>-->
                            <image-uploader
                                :id="comId"
                                v-model="file"
                                :maxWidth="maxSize"
                                :maxHeight="maxSize"
                                :preview="true"
                                :className="['fileInput real-input', { 'fileinput--loaded': hasImage }]"
                                capture="environment"
                                :debug="1"
                                :autoRotate="true"
                                outputFormat="verbose"
                                @input="upload"
                            >
                                <label class="file-label" :for="comId" slot="upload-label">
<!--                                    <figure>-->
<!--                                        <svg-->
<!--                                            xmlns="http://www.w3.org/2000/svg"-->
<!--                                            width="32"-->
<!--                                            height="32"-->
<!--                                            viewBox="0 0 32 32"-->
<!--                                        >-->
<!--                                            <path-->
<!--                                                class="path1"-->
<!--                                                d="M9.5 19c0 3.59 2.91 6.5 6.5 6.5s6.5-2.91 6.5-6.5-2.91-6.5-6.5-6.5-6.5 2.91-6.5 6.5zM30 8h-7c-0.5-2-1-4-3-4h-8c-2 0-2.5 2-3 4h-7c-1.1 0-2 0.9-2 2v18c0 1.1 0.9 2 2 2h28c1.1 0 2-0.9 2-2v-18c0-1.1-0.9-2-2-2zM16 27.875c-4.902 0-8.875-3.973-8.875-8.875s3.973-8.875 8.875-8.875c4.902 0 8.875 3.973 8.875 8.875s-3.973 8.875-8.875 8.875zM30 14h-4v-2h4v2z"-->
<!--                                            ></path>-->
<!--                                        </svg>-->
<!--                                    </figure>-->
                                    <span class="file-cta">{{
                                            hasImage ? "Replace" : "Klikni pro nahrátí obrázku"
                                        }}</span>
                                </label>
                            </image-uploader>
                        </b-field>
<!--                        <div><input id="file" @change="upload" type="file" name="file" ref="uploadInput" accept="image/*" :multiple="false"/></div>-->
                    </div>
                </div>
            </div>
        </template>
        <template v-if="url !== null">
            <div v-if="uploadStep === null" class="button is-primary" v-on:click="setStepToEdit">Upravit obrázek</div>
            <div v-if="uploadStep !== null && uploading === false" class="button is-primary" v-on:click="setStepToNull">Zrušit editaci</div>
        </template>
    </div>
</template>

<script>
import { storage } from '../../firebase-service';
import ImageUploader from './ImageUploader';

export default {
    name: 'UploadComponent2',
    components: {
        ImageUploader
    },
    props: ['url','modelType','imageType','id', 'countId'],
    data() {
        return {
            file: null,
            image: null,
            progressUpload: 0,
            fileName: '',
            uploadTask: '',
            uploading: false,
            uploadEnd: false,
            uploadStep: null,
            hasImage: false,
            maxSize: 1920
        }
    },
    mounted() {
        if (!this.url || this.url.length === 0) {
            this.uploadStep = 'edit';
        }
        if (this.imageType === 'logo') {
            this.maxSize = 512;
        } else if (this.imageType === 'cover') {
            this.maxSize = 1024;
        } else {
            this.maxSize = 1920;
        }
    },
    computed: {
        thumbnail() {
            if (this.image) {
                return this.image;
            } else {
                return this.url
            }
        },
        comId() {
            if (this.countId) {
                return 'iu' + this.imageType + this.countId;
            } else {
                return 'iu' + this.imageType;
            }
        }
    },
    watch: {
        url(val) {
            if (val === null) {
                this.file = null;
                this.image = null;
                this.hasImage = false;
                this.uploadTask = '';
                this.uploading = false;
                this.uploadEnd = false;
                this.uploadStep = 'edit';
                this.hasImage = false;
            }
            //this.$forceUpdate();
        }
    },
    methods: {
        upload(file) {
            //let fileExtension = file.info.name.split('.').pop();

            this.uploading = true;
            let pathToFile = this.modelType + '/' + this.id + '/';
            if (this.imageType === 'cover' || this.imageType === 'logo') {
                this.fileName = this.imageType;// + '.' + fileExtension;
                pathToFile += this.fileName + '.png';
            } else {
                this.fileName = `${new Date().getTime()}`;//.${fileExtension}`;
                pathToFile += this.fileName + '.png';
            }
            let newFile = this.dataURLtoFile(file.dataUrl,this.fileName);
            this.uploadTask = storage.ref(pathToFile).put(newFile);
            this.uploadTask.on('state_changed', snapshot => {
                    this.uploading = true;
                    this.uploadEnd = false;
                    this.progressUpload = Math.floor(snapshot.bytesTransferred / snapshot.totalBytes * 100)
                }, (error) => {
                    console.log(error); //todo error message?
                },
                () => {
                    this.uploadTask.snapshot.ref.getDownloadURL().then(imageURL => {
                        this.$emit("input", imageURL);
                        this.image = imageURL;
                        this.uploadEnd = true;
                        this.uploadStep = null;
                        this.uploading = false;
                    });
                }
            )
        },
        setStepToEdit() {
            this.uploadStep = 'edit';
        },
        setStepToNull() {
            this.uploadStep = null;
        },
        dataURLtoFile(dataurl, filename) {

            var arr = dataurl.split(','),
                mime = arr[0].match(/:(.*?);/)[1],
                bstr = atob(arr[1]),
                n = bstr.length,
                u8arr = new Uint8Array(n);

            while(n--){
                u8arr[n] = bstr.charCodeAt(n);
            }

            return new File([u8arr], filename, {type:mime});
        }
    }
}
</script>

<style>
.image{
    margin-top: 10px;
}
.image-preview{
    border-radius: 100%;
    height: 200px;
    width: 200px;
    background-position: 50% 50%;
    background-size: cover;
}
.image-button{
    margin-top: 10px;
}
.real-input{
    display: none
}
/*.image-load{*/
/*	margin: 20px auto;*/
/*}*/
</style>
