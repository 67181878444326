import { Database } from "@vuex-orm/core";

// models
import User from './models/User';
import UserInfo from './models/UserInfo';
import News from './models/News';
import NewsTranslate from './models/NewsTranslate';
import LoggedUser from './models/LoggedUser';
import Business from './models/Business';
import BusinessTranslate from './models/BusinessTranslate';
import Event from './models/Event';
import EventDetail from './models/EventDetail';
import RealEstate from './models/RealEstate';
import Enum from './models/Enum';
import Notification from './models/Notification';
import Building from './models/Building';
import Parking from './models/Parking';
import ParkingTranslate from './models/ParkingTranslate';

// modules
import user from './modules/user';
import userinfo from './modules/userinfo';
import news from './modules/news';
import newstranslate from './modules/newstranslate';
import business from './modules/business';
import businesstranslate from './modules/businesstranslate';
import event from './modules/event';
import eventdetail from './modules/eventdetail';
import realestate from './modules/realestate';
import enums from './modules/enum';
import notification from './modules/notification';
import building from "./modules/building";
import parking from "./modules/parking";
import parkingtranslate from "./modules/parkingtranslate";


const database = new Database();
database.register(User, user);
database.register(UserInfo, userinfo);
database.register(News, news);
database.register(NewsTranslate, newstranslate);
database.register(LoggedUser);
database.register(Business, business);
database.register(BusinessTranslate, businesstranslate);
database.register(Event, event);
database.register(EventDetail, eventdetail);
database.register(RealEstate, realestate);
database.register(Enum, enums);
database.register(Notification, notification);
database.register(Building, building);
database.register(Parking, parking);
database.register(ParkingTranslate, parkingtranslate);

export default database;
