import Model from './BaseEntity';

export default class BusinessTranslate extends Model {

    static entity = 'businesstranslate';

    static fields() {
        return {
            id: this.attr(''),
            parent_id: this.attr(null).nullable(),
            description: this.string(null).nullable(),
            images: this.attr([]),
            opens: this.attr([]),
        }
    }

    get serialized() {
        return {
            id: this.id,
            description: this.description,
            images: this.images,
            opens: this.opens
        }
    }

}
