<template>
    <section class="section">
        <div class="block is-flex is-justify-content-flex-end">
            <b-button :to="{ name: 'PageBusinessForm' }" tag="router-link" class="is-success">
                <b-icon icon="plus-circle" size="is-small" class="mr-1"></b-icon>Vytvořit obchod/službu
            </b-button>
        </div>
        <b-table
            ref="tbusiness"
            :data="this.business"
            :per-page="perPage"
            :paginated="true"
            :current-page.sync="currentPage"
            :pagination-simple="true"
            :pagination-position="'bottom'"
            :rowClass="rowClass"
            draggable
            @dragstart="dragstart"
            @drop="drop"
            @dragover="dragover"
            @dragleave="dragleave"
        >
            <b-table-column field="category" label="Kategorie" :searchable="true">
                <template slot="searchable" slot-scope="props">
                    <b-select v-if="enums" placeholder="Vyber kategorii" v-model="props.filters[props.column.field]">
                        <option
                            :value="null"
                            :key="'0a'">
                            Zrušit výběr
                        </option>
                        <option
                            v-for="option in enums.category"
                            :value="option"
                            :key="option">
                            {{ $t('business.category.' + option) }}
                        </option>
                    </b-select>
<!--                    <template v-if="enums != null">-->
<!--                    <b-autocomplete-->
<!--                        v-model="props.filters[props.column.field]"-->
<!--                        field="value"-->
<!--                        open-on-focus-->
<!--                        :data="makeEnums(enums)"-->
<!--                    >-->
<!--                    </b-autocomplete>-->
<!--                    </template>-->
                </template>
                <template v-slot="props">{{ $t('business.category.' + props.row.category) }}</template>

            </b-table-column>
            <b-table-column field="name.cs_CZ" label="Název" v-slot="props" :searchable="true">
                {{ props.row.name.cs_CZ }}
            </b-table-column>
            <b-table-column field="contact.name" label="Kontakt" v-slot="props" :searchable="true">
                {{ props.row.contact.name }}
            </b-table-column>
            <b-table-column field="contact.address" label="Adresa" v-slot="props" :searchable="true">
                {{ props.row.contact.address }}
            </b-table-column>
            <b-table-column field="action" label="" v-slot="props">
                <div class="buttons">
                    <b-button v-on:click="edit(props.row.id)">
                        <b-icon icon="circle-edit-outline" size="is-small" class="mr-1"></b-icon>Upravit
                    </b-button>
                    <b-button v-on:click="deleteDoc(props.row.id)" type="is-danger">
                        <b-icon icon="circle-edit-outline" size="is-small" class="mr-1"></b-icon>Smazat
                    </b-button>
                    <b-button v-on:click="toggleHide(props.row)" :type="props.row.state !== 'hidden' ? 'is-light' : 'is-success is-light'">
                        <b-icon icon="eye" size="is-small" class="mr-1"></b-icon>{{ props.row.state !== 'hidden' ? 'Skrýt' : 'Publikovat' }}
                    </b-button>
                </div>
            </b-table-column>
        </b-table>
    </section>
</template>

<script>
import Business from '@/store/models/Business';
import Enum from "@/store/models/Enum";

export default {
    name: 'PageBusiness',
    data() {
        return {
            currentPage: 1,
            perPage: 30
        }
    },
    computed: {
        business() {
            return this.$store.getters['entities/business/query']().withAll().orderBy('category').orderBy('order').get();
        },
        enums() {
            return this.$store.getters["entities/enum/query"]()
                .find('business') || null;
        },
    },
    methods: {
        edit(id) {
            this.$router.push({name: 'PageBusinessForm', params: { id } });
        },
        async deleteDoc(id) {
            let lc = this.$buefy.loading.open();
            await Business.delete(id);
            await Business.fetch();
            lc.close();
        },
        makeEnums(ee) {
            let data = [];
            let category = ee.category;
            for (let i = 0; category.length > i; i++) {
                data.push(
                    {
                        text: this.$t('business.category.' + category[i]),
                        value: category[i]
                    }
                );
            }
            return data;
        },
        toggleHide(item) {
            if (item.state === 'hidden') {
                this.update(item.id, 'public');
            } else {
                this.update(item.id, 'hidden');
            }
        },
        async update(id, value) {
            let lc = this.$buefy.loading.open();
            await Business.update({ id: id, data: {state: value} });
            await Business.fetch();
            lc.close();
        },
        rowClass(item) {
            if(item.state === 'hidden'){
                return 'is-checked-as-hidden';
            }
        },
        dragstart(payload) {
            this.draggingRow = payload.row;
            this.draggingRowIndex = payload.index;
            payload.event.dataTransfer.effectAllowed = 'move';
        },
        dragover(payload) {
            payload.event.dataTransfer.dropEffect = 'move';
            payload.event.target.closest('tr').classList.add('is-selected');
            payload.event.preventDefault();
        },
        dragleave(payload) {
            payload.event.target.closest('tr').classList.remove('is-selected');
            payload.event.preventDefault();
        },
        async drop(payload) {
            const dropedOnRow = payload.row;
            const droppedOnRowIndex = payload.index;
            payload.event.target.closest('tr').classList.remove('is-selected');
            this.$buefy.toast.open(`Moved ${this.draggingRow.id} from row ${this.draggingRowIndex + 1} to ${dropedOnRow.id} from row ${droppedOnRowIndex + 1}`);

            if (
                this.draggingRowIndex !== droppedOnRowIndex
                // || (droppedOnRowIndex - this.draggingRowIndex) === 1
            ) {
                let data = this.$refs.tbusiness.newData;
                let counter = -1;
                for(let i = 0; i < data.length; i++) {
                    counter++;
                    // console.log('counter=' + counter + ' i=' + i + ' pick=(' + this.draggingRowIndex + ',' + this.draggingRow.id + ')' + ' drop=(' + droppedOnRowIndex + ',' + dropedOnRow.id + ')');
                    //co nothing for unaffected row
                    if (
                        (i < this.draggingRowIndex && i < droppedOnRowIndex ) ||
                        (i > this.draggingRowIndex && i > droppedOnRowIndex )
                    ) {
                        continue;
                    } else {
                        if (this.draggingRowIndex < droppedOnRowIndex) {
                            if (data[i].id === this.draggingRow.id) {
                                counter--;
                                //put row to new position droppedOnRowIndex
                                await this.updateOrder(data[i].id, droppedOnRowIndex);
                            } else {
                                //put row to counter position
                                // if (data[i].id === this.draggingRow.id) {
                                //     continue;
                                // }
                                await this.updateOrder(data[i].id, counter);
                            }
                        } else {
                            if (data[i].id === dropedOnRow.id) {
                                //put this.draggingRow to counter position
                                await this.updateOrder(this.draggingRow.id, counter);
                                counter++;
                                //put row to counter position
                                await this.updateOrder(data[i].id, counter);
                            } else {
                                //skip droppedOnRowIndex position
                                if (data[i].id === this.draggingRow.id) {
                                    continue;
                                }
                                //put row to counter position
                                await this.updateOrder(data[i].id, counter);
                            }
                        }
                    }
                }
            }
            await Business.fetch();
        },
        async updateOrder(id, order) {
            // console.log(id, order);
            await Business.update({
                id: id,
                data: {order: order},
                translations: []
            });
        }
    },
    async created() {
        let lc = this.$buefy.loading.open();
        await Business.fetch();
        await Enum.fetch();
        lc.close();
    }
}
</script>
