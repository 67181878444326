import Vue from 'vue';
import VueRouter from 'vue-router';
import firebase from 'firebase';

Vue.use(VueRouter);

import PageLogin from "@/views/PageLogin";

// lists
import PageNewsList from "@/views/List/PageNewsList";
import PageBusinessList from "@/views/List/PageBusinessList";
import PageEventList from "@/views/List/PageEventList";
import PageRealEstateList from "@/views/List/PageRealEstateList";
import PageNotificationList from "@/views/List/PageNotificationList";
import PageParkingList from "@/views/List/PageParkingList";
import PageUserList from "@/views/List/PageUserList";

// forms
import PageNewsForm from "@/views/Form/PageNewsForm";
import PageEventForm from "@/views/Form/PageEventForm";
import PageBusinessForm from "@/views/Form/PageBusinessForm";
import PageRealEstateForm from "@/views/Form/PageRealEstateForm";
import PageNotificationForm from "@/views/Form/PageNotificationForm";
import PageParkingForm from "@/views/Form/PageParkingForm";

// other pages
import PageDashboard from "@/views/Dashboard/PageDashboard";

// others
import LoggedUser from "@/store/models/LoggedUser";



const routes = [
    {
        component: PageLogin,
        name: 'PageLogin',
        path: '/',
        meta: {
            requiresAuth: false
        }
    },
    {
        component: PageDashboard,
        name: 'PageDashboard',
        path: '/dashboard',
        meta: {
            requiresAuth: true
        }
    },
    // --- ROUTERS: news
    {
        component: PageNewsList,
        name: 'PageNewsList',
        path: '/news',
        meta: {
            requiresAuth: true
        }
    },
    {
        component: PageNewsForm,
        name: 'PageNewsForm',
        path: '/news-form/:id?',
        meta: {
            requiresAuth: true
        }
    },
    // --- ROUTERS: business
    {
        component: PageBusinessList,
        name: 'PageBusinessList',
        path: '/business',
        meta: {
            requiresAuth: true
        }
    },
    {
        component: PageBusinessForm,
        name: 'PageBusinessForm',
        path: '/business-form/:id?',
        meta: {
            requiresAuth: true
        }
    },
    // --- ROUTERS: events
    {
        component: PageEventList,
        name: 'PageEventList',
        path: '/events',
        meta: {
            requiresAuth: true
        }
    },
    {
        component: PageEventForm,
        name: 'PageEventForm',
        path: '/event-form/:id?',
        meta: {
            requiresAuth: true
        }
    },
    // --- ROUTERS: real estates
    {
        component: PageRealEstateList,
        name: 'PageRealEstateList',
        path: '/real-estates',
        meta: {
            requiresAuth: true
        }
    },
    {
        component: PageRealEstateForm,
        name: 'PageRealEstateForm',
        path: '/real-estate-form/:id?',
        meta: {
            requiresAuth: true
        }
    },
    // --- ROUTERS: notification
    {
        component: PageNotificationList,
        name: 'PageNotificationList',
        path: '/notification',
        meta: {
            requiresAuth: true
        }
    },
    {
        component: PageNotificationForm,
        name: 'PageNotificationForm',
        path: '/notification-form/:id?',
        meta: {
            requiresAuth: true
        }
    },
    // --- ROUTERS: parking
    {
        component: PageParkingList,
        name: 'PageParkingList',
        path: '/parking',
        meta: {
            requiresAuth: true
        }
    },
    {
        component: PageParkingForm,
        name: 'PageParkingForm',
        path: '/parking-form/:id?',
        meta: {
            requiresAuth: true
        }
    },
    // --- ROUTERS: users
    {
        component: PageUserList,
        name: 'PageUserList',
        path: '/user',
        meta: {
            requiresAuth: true
        }
    },
];

const router = new VueRouter({
    routes
});

router.beforeEach((to, from, next) => {
   firebase.auth().onAuthStateChanged(user => {
       const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
       if (requiresAuth) {
           if (user === null) {
               LoggedUser.deleteAll();
               next({ name: 'PageLogin' });
           } else {
               if (LoggedUser.query().first() === null) {
                   LoggedUser.create({
                       data: { id: user.uid, email: user.email }
                   });
               }
               firebase
                   .firestore()
                   .collection('access')
                   .doc(user.uid)
                   .get()
                   .then( access => {
                       if (access.data()) {
                           let accessData = access.data();
                           if (accessData.admin) {
                               next();
                           } else {
                               this.$buefy.dialog.alert('Přístup zamítnut.');
                               LoggedUser.deleteAll();
                               next({ name: 'PageLogin' });
                           }
                       } else {
                           this.$buefy.dialog.alert('Přístup zamítnut.');
                           LoggedUser.deleteAll();
                           next({ name: 'PageLogin' });
                       }
                   }).catch(() => {
                   this.$buefy.dialog.alert('Přístup zamítnut.');
                   LoggedUser.deleteAll();
                   next({ name: 'PageLogin' });
               });
           }
       } else {
           next();
       }
   });
});

export default router;
