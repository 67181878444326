import firebase from 'firebase/app'
import 'firebase/auth';
import 'firebase/firestore';
const axios = require('axios');

const config = {
    apiKey: "AIzaSyCtCQbjonXqEeE5e5u0mZ6BUBFD6AstWTk",
    authDomain: "bb-centrum.firebaseapp.com",
    databaseURL: "https://bb-centrum-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "bb-centrum",
    storageBucket: "bb-centrum.appspot.com",
    messagingSenderId: "186327147902",
    appId: "1:186327147902:web:3c24677ceac2f12363ff2f",
    measurementId: "G-R8QYP0LW0V"
};

firebase.initializeApp(config);

export const getOne = (_type, _id) => {
    let entities = [];
    return firebase
        .firestore()
        .collection(_type)
        .doc(_id)
        .get()
        .then(doc => {
            entities.push({
                ...doc.data(),
                id: doc.id
            });
            return entities;
        })
        .catch(error => {
            return error;
        })
}

export const getAll = _type => {
    let entities = [];
    return firebase
        .firestore()
        .collection(_type)
        .get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc => {
                // doc.data() is never undefined for query doc snapshots
                entities.push({
                    ...doc.data(),
                    id: doc.id
                });
            });
            return entities;
        })
        .catch(error => {
            return error;
        });
};

export const getAllTranslations = (_type, _id_parent) => {
    let entities = [];
    return firebase
        .firestore()
        .collection(_type)
        .doc(_id_parent)
        .collection('data')
        .get()
        .then(querySnapshot => {
            querySnapshot.forEach(doc => {
                // doc.data() is never undefined for query doc snapshots
                entities.push({
                    ...doc.data(),
                    id: doc.id,
                    id_parent: _id_parent
                });
            });
            return entities;
        })
        .catch(error => {
            return error;
        });
};

export const update = (_type, _id, _data) => {
    return firebase
        .firestore()
        .collection(_type)
        .doc(_id)
        .update(_data)
        .then(result => {
            return result;
        })
        .catch(error => {
            return error;
        })
};

export const updateTranslate = (_type, _id_parent, _id, _data) => {
    return firebase
        .firestore()
        .collection(_type)
        .doc(_id_parent)
        .collection('data')
        .doc(_id)
        .update(_data)
        .then(result => {
            return result;
        })
        .catch(error => {
            return error;
        })
};

export const create = (_type, _id, _data) => {
    _data.owner = firebase.auth().currentUser.uid;
    let collection = firebase
        .firestore()
        .collection(_type);
    let ref = collection.doc(_id);
    let entity = _data;

    return ref.set(entity, { merge: true })
        .then(() => {
            return entity;
        }).catch(error => {
            return error;
        })
};

export const createTranslate = (_type, _id_parent, _id, _data) => {
    let collection = firebase
        .firestore()
        .collection(_type)
        .doc(_id_parent)
        .collection('data');
    let ref = collection.doc(_id);
    let entity = _data;

    return ref.set(entity, { merge: true })
        .then(() => {
            return entity;
        }).catch(error => {
            return error;
        })
};

export const generateId = (_type) => {
    return firebase
        .firestore()
        .collection(_type)
        .doc()
        .id;
};

export const deleteDocument = (_type, _id) => {
    return firebase
        .firestore()
        .collection(_type)
        .doc(_id)
        .delete()
        .then(() => {
            return true;
        }).catch((error) => {
            console.error("Error removing document: ", error);
            return false;
        });
};

export const deleteCollectionDocument = (_type, _id, _collection, _id_doc) => {
    return firebase
        .firestore()
        .collection(_type)
        .doc(_id)
        .collection(_collection)
        .doc(_id_doc)
        .delete()
        .then(() => {
            return true;
        }).catch((error) => {
            console.error("Error removing document: ", error);
            return false;
        });
};

export const getUserInfo = () => {
    let key = firebase.auth().currentUser.uid;

    return axios
        .get('https://us-central1-bb-centrum.cloudfunctions.net/adminListAllUsers?key=' + key)
        .then(res => {
            return res.data;
        })
        .catch(error => {
            return error;
        });
}

export const storage = firebase.storage();

export const firestore = firebase.firestore();

export const myFirebase = firebase;
